import { useState } from 'react';
import { ROUTE_CRMA_REPORT_DATA } from "../../routes/Routes";
import GetFetch from "../../hooks/GetFetch";
import DataGrid, { 
    Column,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid";
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import { BaseButton } from '../../components/BaseButton/BaseButton';
import { BaseDateField } from '../../components/BaseDateField/BaseDateField';
import { LoadPanel } from 'devextreme-react';
import { GET_REPORTS_DATA } from '../../Enums/endpoints';
import { allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import usePageTitle from '../../hooks/usePageTitle';
import './CRMA_ReportData.scss';


const CRMA_ReportData = () => {
    const [billingDateValue, setBillingDateValue] = useState();
    const [billingDateParam, setBillingDateParam] = useState();
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState();
    const [error, setError] = useState(false);
    const { title } = ROUTE_CRMA_REPORT_DATA;

    usePageTitle(title);

    const handleSubmit = async () => {
        setLoading(true);
        
        try {
            const { Errors, Message, Success } = await GetFetch(`${GET_REPORTS_DATA}=${billingDateParam}`);

            if (Success) {
                const data = Message.map(item =>
                    Object.fromEntries(
                        Object.entries(item).map(([key, value]) => 
                            [key, value ?? "N/A"]
                        )
                    )
                );
                
                setReportData(data);
                setError(false);
            };
            
            if (Errors.length > 0) {
                setError(true);
                console.error(Errors)
            };

        } catch (error) {
            console.error("Error on pre-fetch: ", error);
        } finally {
            setLoading(false);
        };
    };

    const handleValueChange = ({ value }) => {
        setBillingDateValue(value); 

        const date = new Date(value);

        if (!isNaN(date.getTime())) {
            const month = String(date.getMonth() + 1 ).padStart(2, '0');
            const year = date.getFullYear();
            const monthAndYear = `${month}-${year}`;
            setBillingDateParam(monthAndYear);
        };
    };

    return (
        <>
            <div className="block">
                <h1> { title } </h1>
            </div>
            <div className="block__div--wrapper">
                <BaseDateField 
                    displayFormat={"monthAndYear"}
                    enableValidation={true}
                    inputAttr={{ "aria-label": "Billing Date" }}
                    isValid={billingDateValue}
                    label={"Select a Bill Date"}
                    valueUpdatedByUser={handleValueChange}
                    value={billingDateValue}
                    variant={"outlined"}
                />
                <BaseButton 
                    label={"Submit"}
                    onClick={handleSubmit}
                    variant={"outlined"}
                />
            </div>
            <LoadPanel 
                showIndicator 
                showPane 
                visible={loading} 
            />
            {
                reportData && reportData.length > 0 &&
                <div className="block block__div--wrapper">
                    <DataGrid
                        columnAutoWidth={true}
                        dataSource={reportData}
                        onCellPrepared={CustomPreparedHeaderCell}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <KeyboardNavigation enabled={true} />
                        <SearchPanel 
                            highlightCaseSensitive={true} 
                            visible={true} 
                        />
                        <Selection mode="single" />
                        <Paging defaultPageSize={10} />
                        <Pager
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                        />
                        <Column 
                            dataField="id"
                            visible={false}
                        />
                        <Column 
                            caption="Report"
                            dataField="reportKey"
                        />
                        <Column 
                            caption="Approval Status"
                            dataField="ApprovalStatus"
                        />
                        <Column 
                            caption="Bill Date"
                            dataField="billDate"
                        />
                        <Column 
                            caption="Created By"
                            dataField="CreatedBy"
                        />
                        <Column 
                            caption="Date Created"
                            dataField="CreatedDate"
                        />
                        <Column 
                            caption="Updated By"
                            dataField="UpdatedBy"
                        />
                        <Column 
                            caption="Date Updated"
                            dataField="UpdatedDate"
                        />
                    </DataGrid>
                </div>
            }
            {
                reportData && reportData.length === 0 &&
                <div className="block block__div--wrapper">
                    No records were found.
                </div>
            }
            {
                error && 
                <div className="block block__div--wrapper">
                    An error occurred, which prevented report data was not returned.
                </div>
            }
        </>
    );
};

export default CRMA_ReportData;