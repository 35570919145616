import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import Paper from '@mui/material/Paper';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';

const UserSession = ({ data }) => {
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {
            const mappedData = data.map((element) => {
                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    DateChanged: (element.DateChanged === null || element.DateChanged === "")
                     ? "N/A" : dayjs(element.DateChanged).utc().format("MM/DD/YYYY h:mma"),
                    UpdatedBy: element.ChangeType === "Insert" ? element.ChangedValue.CreatedBy : element.InitialValue.CreatedBy,
                    Status: element.ChangeType === "Insert" ? "Session Started" : "Session Ended"
                }

            });
            return mappedData;
        }
    });

    return (
        <div>
            <FOUOheader
            dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}                
            heading={"Audit"}
                userEmail={user.email}
            />
            <Paper
                elevation={5}
                sx={{ padding: '30px', marginTop: '7px' }}
            >
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={e => ExportGrid(e, "Audit_Report")}
            >
                <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                <Column dataField="DateChanged" caption="Date Changed (UTC)" />
                <Column dataField="UpdatedBy" caption="User" />
                <Column dataField="Status" caption="Session Status" />

                {/* <FilterRow visible={true} /> */}
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
            </DataGrid>
                <FOUOfooter />
            </Paper>
        </div>
    )
}
export default UserSession;
