import { useMemo } from "react";
import DataGrid, {
    Column,
    Export,
    GroupItem,
    GroupPanel,
    KeyboardNavigation,
    Paging,
    Pager,
    SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import { allowedPageSizes, pageFormats } from "../../components/GlobalDataGridConfigurations";
import CustomStore from 'devextreme/data/custom_store';
import ExportGrid from '../../components/ExportGrid';

export default function DataGridSpecificCostEstimateSummary(
    { 
        summaryCosts, 
        fiscalYear, 
        groupingEnabled 
    }
){
    const handleExport = (e) => {
        ExportGrid(e, `Specific_Cost_Estimate_Summary_${fiscalYear}`);
    };

    const summaryData = useMemo(() => new CustomStore({
        key: 'ID',
        load: async () => {
            if (summaryCosts !== null) {
                const combinedSecurityDetailsRecords = new Map();
                summaryCosts.SecurityCostDetails.forEach(securityDetail => {
                    const key = `${securityDetail.Region}-${securityDetail.BuildingSequenceNo}-${securityDetail.FeeTypeId}`;
                    const psoCost = securityDetail.SecurityCostType === "PSO" ? securityDetail.Charges : null;
                    const tcmCost = securityDetail.SecurityCostType === "TCM" ? securityDetail.Charges : null;
                    const amCapCost = securityDetail.SecurityCostType === "AMORCAP" ? securityDetail.Charges : null;
                    const feeType = securityDetail.FeeTypeId === 2 ? "Building" : (securityDetail.FeeTypeId === 3 ? "Agency": null);

                    if (combinedSecurityDetailsRecords.has(key)) {
                        const existingSecurityDetailRecord = combinedSecurityDetailsRecords.get(key);
                        combinedSecurityDetailsRecords.set(key, {
                            ...existingSecurityDetailRecord,
                            PsoCost: existingSecurityDetailRecord.PsoCost + psoCost,
                            TcmCost: existingSecurityDetailRecord.TcmCost + tcmCost,
                            AmCapCost: existingSecurityDetailRecord.AmCapCost + amCapCost,
                            TotalFyCost: (psoCost+ tcmCost + amCapCost) + existingSecurityDetailRecord.TotalFyCost
                        })

                    } else {
                        combinedSecurityDetailsRecords.set(key, {
                            FeeType: feeType,
                            ID: securityDetail.SecurityCostId,
                            FiscalYear: securityDetail.FiscalYear,
                            Region: securityDetail.Region,
                            BuildingNumber: securityDetail.BuildingSequenceNo,
                            CustomerName: securityDetail.CustomerName,
                            CustomerNumber: securityDetail.CustomerNumber,
                            PsoCost: psoCost,
                            TcmCost: tcmCost,
                            AmCapCost: amCapCost,
                            TotalFyCost: (psoCost+ tcmCost + amCapCost) 
                        })
                    }
                });

                return Array.from(combinedSecurityDetailsRecords.values());                   
            }
            return [];
        }
    }), [summaryCosts, fiscalYear]);

    return (
        <DataGrid
            columnAutoWidth={true}
            dataSource={summaryData}
            onCellPrepared={null}
            onExporting={e => handleExport(e)}
            rowAlternationEnabled={true}
            showBorders={true}
            showRowLines={true}
            style={{ color: "#06152B" }}
        >
            <KeyboardNavigation enabled={true} />
            <Paging defaultPageSize={10} />
            <Pager
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
            />
            <SearchPanel
                highlightCaseSensitive={true}
                visible={true}
            />
            <GroupPanel 
                visible={true}
                // allowColumnDragging={true} 
                />
            <Column 
                // allowColumnDragging={true} 
                caption="Fee Type" 
                dataField="FeeType" 
                groupIndex={groupingEnabled ? null : 2}
            />
            <Column 
                // allowColumnDragging={true} 
                caption="Region" 
                dataField="Region" 
                groupIndex={groupingEnabled ? null : 0} 
            />
            <Column 
                caption="Building Number" 
                dataField="BuildingNumber" 
                groupIndex={groupingEnabled ? null : 0}
                // allowColumnDragging={true}
            />
            {/* <Column 
                caption="Customer Name" 
                dataField="CustomerName" 
                allowColumnDragging={false}

            />
            <Column 
                allowColumnDragging={false} 
                caption="Customer Number" 
                dataField="CustomerNumber" 
            /> */}
            <Column 
                // allowColumnDragging={false} 
                caption="PSO FY Cost Estimate" 
                dataField="PsoCost" 
                format="currency" 
            />
            <Column 
                // allowColumnDragging={false} 
                caption="TCM FY Cost Estimate" 
                dataField="TcmCost" 
                format="currency" 
            />
            <Column 
                // allowColumnDragging={false} 
                caption="AM CAP FY Cost Estimate" 
                dataField="AmCapCost" 
                format="currency" 
            />
            <Column 
                allowColumnDragging={false} 
                caption="Total FY Cost Estimate" 
                dataField="TotalFyCost" 
                format="currency" 
            />
            <Summary>
                <GroupItem
                    alignByColumn={true}
                    column="PsoCost"
                    displayFormat="PSO FY Cost Estimate: {0}" 
                    summaryType="sum"
                    valueFormat="currency"
                />
                <GroupItem
                    alignByColumn={true}
                    column="TcmCost"
                    displayFormat="TCM FY Cost Estimate: {0}" 
                    summaryType="sum"
                    valueFormat="currency"
                />
                <GroupItem
                    alignByColumn={true}
                    column="AmCapCost"
                    displayFormat="AM CAP FY Cost Estimate: {0}" 
                    summaryType="sum"
                    valueFormat="currency"
                />
                <GroupItem
                    alignByColumn={true}
                    column="TotalFyCost"
                    displayFormat="Total FY Cost Estimate: {0}" 
                    summaryType="sum"
                    valueFormat="currency"
                />
                <TotalItem
                    alignByColumn={true} 
                    column="PsoCost"
                    displayFormat="PSO Grand Total: {0}"
                    summaryType="sum"
                    valueFormat="currency"
                />
                <TotalItem
                    alignByColumn={true} 
                    column="TcmCost"
                    displayFormat="TCM Grand Total: {0}"
                    summaryType="sum"
                    valueFormat="currency"
                />
                <TotalItem
                    alignByColumn={true} 
                    column="AmCapCost"
                    displayFormat="AM CAP Grand Total: {0}"
                    summaryType="sum"
                    valueFormat="currency"
                />
                <TotalItem
                    alignByColumn={true} 
                    column="TotalFyCost"
                    displayFormat="Grand Total: {0}"
                    summaryType="sum"
                    valueFormat="currency"
                />
            </Summary> 
            <Export
                enabled={groupingEnabled}
                formats={pageFormats}
            />
        </DataGrid>
    );
};