import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { DoneOutline, SummarizeOutlined, CheckRounded } from '@mui/icons-material';
import DoNotTouchRoundedIcon from '@mui/icons-material/DoNotTouchRounded';
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import NavDropDownItem from './NavListUtility/NavDropDownItem';
import NavListItem from './NavListUtility/NavListItem';
import { SettingsSuggest } from '@mui/icons-material';
import { SettingsSystemDaydreamOutlined } from '@mui/icons-material';
import {
    ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT,
    ROUTE_COST_ESTIMATE_REPORT,
    ROUTE_FY_COST_ESTIMATE,
    ROUTE_HELP,
    ROUTE_REGION_AND_BUILDING_DATA,
    ROUTE_ROLES,
    ROUTE_ACTIVE_SESSIONS,
    ROUTE_USERS,
    ROUTE_WELCOME,
    ROUTE_AUDIT_REPORT,
    ROUTE_USER_REPORT,
    ROUTE_CONTRACTS_PAGE,
    ROUTE_CONTRACT_COST_ESTIMATES,
    ROUTE_PSO_CONTRACT_SUMMARY_PAGE,
    ROUTE_CUSTOMERS,
    ROUTE_BILLING_ADJUSTMENTS,
    ROUTE_CRMA_UPLOAD,
    ROUTE_CRMA_DOWNLOAD,
    ROUTE_ADJUSTMENTS_REPORT,
    ROUTE_WORKFLOW_TASKS,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION,
    ROUTE_SECURITY_CONTACTS,
    ROUTE_BASIC_SECURITY_INFORMATION
} from '../routes/Routes';
import { useAuth } from '../hooks/useAuth';
import BasicSecurityInformation from '../Security/BasicSecurityInformation/BasicSecurityInformation';

const NavList = () => {
    const _user = JSON.parse(localStorage.getItem("user"));
    const modules = _user?.role?.ModulePermissions?.map(module => { if (module.Read) return module.Page });
    return (
        <Fragment>
            <Divider />
            <List>
                <NavListItem
                    title={ROUTE_WELCOME.title}
                    destination={ROUTE_WELCOME.withSlash}
                    icon={<HomeOutlinedIcon />}
                    itemButtonId="home_navlist_043"
                />
            </List>
            <Divider />
            <List>
                <NavListItem
                    title={ROUTE_WORKFLOW_TASKS.title}
                    destination={ROUTE_WORKFLOW_TASKS.withSlash}
                    icon={<CheckRounded />}
                    allowed={modules?.includes(ROUTE_WORKFLOW_TASKS.module)}
                    itemButtonId="bt_workflow_data_001"
                />
                <NavListItem
                    title={ROUTE_REGION_AND_BUILDING_DATA.title}
                    destination={ROUTE_REGION_AND_BUILDING_DATA.withSlash}
                    icon={<GridViewIcon />}
                    allowed={modules?.includes(ROUTE_REGION_AND_BUILDING_DATA.module)}
                    itemButtonId="bt_region_and_building_data_001"
                />

                <NavListItem
                    title={ROUTE_FY_COST_ESTIMATE.title}
                    destination={ROUTE_FY_COST_ESTIMATE.withSlash}
                    icon={<RequestQuoteOutlinedIcon />}
                    allowed={modules?.includes(ROUTE_FY_COST_ESTIMATE.module)}
                    itemButtonId="bt_FY_COST_ESTIMATE_002"
                />
                <NavListItem
                    title={ROUTE_CONTRACTS_PAGE.title}
                    destination={ROUTE_CONTRACTS_PAGE.withSlash}
                    icon={<HistoryEduRoundedIcon />}
                    allowed={modules?.includes(ROUTE_CONTRACTS_PAGE.module)}
                    itemButtonId="bt_CONTRACTS_PAGE_003"
                />
                <NavListItem
                    title={ROUTE_PSO_CONTRACT_SUMMARY_PAGE.title}
                    destination={ROUTE_PSO_CONTRACT_SUMMARY_PAGE.withSlash}
                    icon={<HistoryEduRoundedIcon />}
                    allowed={modules?.includes(ROUTE_PSO_CONTRACT_SUMMARY_PAGE.module)}
                    itemButtonId="bt_CONTRACTS_PAGE_003"
                />
                <NavListItem
                    title={ROUTE_CONTRACT_COST_ESTIMATES.title}
                    destination={ROUTE_CONTRACT_COST_ESTIMATES.withSlash}
                    icon={<AccountBalanceRoundedIcon />}
                    allowed={modules?.includes(ROUTE_CONTRACT_COST_ESTIMATES.module)}
                    itemButtonId="bt_CONTRACT_COST_ESTIMATES_004"
                />
                <NavListItem
                    title={ROUTE_CUSTOMERS.title}
                    destination={ROUTE_CUSTOMERS.withSlash}
                    icon={<AccountCircleOutlinedIcon />}
                    allowed={modules?.includes(ROUTE_CUSTOMERS.module)}
                    itemButtonId="bt_CUSTOMERS_005"
                />
                <NavDropDownItem
                    title={ROUTE_BILLING_ADJUSTMENTS.title}
                    icon={<EditOutlinedIcon />}
                    itemButtonId="bt_BILLING_ADJUSTMENTS_006"
                    elements={
                        <List component='div' disablePadding>
                            <NavListItem
                                indent={true}
                                title={ROUTE_BILLING_ADJUSTMENTS.title}
                                destination={ROUTE_BILLING_ADJUSTMENTS.withSlash}
                                icon={<EditOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_BILLING_ADJUSTMENTS.module)}
                                itemButtonId="bt_BILLING_ADJUSTMENTS_006"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_CRMA_UPLOAD.title}
                                destination={ROUTE_CRMA_UPLOAD.withSlash}
                                icon={<RequestQuoteOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_CRMA_UPLOAD.module)}
                                itemtButtonId="bt_CRMA_001"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_CRMA_DOWNLOAD.title}
                                destination={ROUTE_CRMA_DOWNLOAD.withSlash}
                                icon={<RequestQuoteOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_CRMA_DOWNLOAD.module)}
                                itemtButtonId="bt_CRMA_002"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.title}
                                destination={ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.withSlash}
                                icon={<RequestQuoteOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.module)}
                                itemtButtonId="bt_CRMA_002"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.title}
                                destination={ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.withSlash}
                                icon={<RequestQuoteOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.module)}
                                itemtButtonId="bt_CRMA_002"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.title}
                                destination={ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.withSlash}
                                icon={<RequestQuoteOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.module)}
                                itemtButtonId="bt_CRMA_002"
                            />
                        </List>
                    }
                />
                <NavDropDownItem
                    title='Reports'
                    icon={<AssessmentOutlinedIcon />}
                    itemButtonId="bt_REPORTS_014"
                    elements={
                        <List component='div' disablePadding>
                            <NavListItem
                                indent={true}
                                title={ROUTE_COST_ESTIMATE_REPORT.title}
                                destination={ROUTE_COST_ESTIMATE_REPORT.withSlash}
                                icon={<AttachMoneyRoundedIcon />}
                                allowed={modules?.includes(ROUTE_COST_ESTIMATE_REPORT.module)}
                                itemButtonId="bt_COST_ESTIMATE_REPORT_007"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.title}
                                destination={ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.withSlash}
                                icon={<AccountBalanceRoundedIcon />}
                                allowed={modules?.includes(ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.module)}
                                itemButtonId="bt_BUILDING_SPECIFIC_ESTIMATE_REPORT_008"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_ADJUSTMENTS_REPORT.title}
                                destination={ROUTE_ADJUSTMENTS_REPORT.withSlash}
                                icon={<CreateRoundedIcon />}
                                allowed={modules?.includes(ROUTE_ADJUSTMENTS_REPORT.module)}
                                itemButtonId="bt_BUILDING_SPECIFIC_ESTIMATE_REPORT_009"
                            />
                        </List>
                    }
                />
                    <NavDropDownItem
                    title='Security'
                    icon={<AssessmentOutlinedIcon />}
                    itemButtonId="bt_SECURITY_001"
                    elements={
                        <List component='div' disablePadding>
                            <NavListItem
                                indent={true}
                                title={ROUTE_BASIC_SECURITY_INFORMATION.title}
                                destination={ROUTE_BASIC_SECURITY_INFORMATION.withSlash}
                                icon={<AssessmentOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_BASIC_SECURITY_INFORMATION.module)}
                                itemButtonId="bt_BASIC_SECURITY_INFORMATION_001"
                            />
                            <NavListItem
                                indent={true}
                                title={ROUTE_SECURITY_CONTACTS.title}
                                destination={ROUTE_SECURITY_CONTACTS.withSlash}
                                icon={<AssessmentOutlinedIcon />}
                                allowed={modules?.includes(ROUTE_SECURITY_CONTACTS.module)}
                                itemButtonId="bt_ROUTE_SECURITY_CONTACTS_001"
                            />
                        </List>
                    }
                />
            
                {(_user && _user.AdminUser !== null && _user.AdminUser) &&
                    <NavDropDownItem
                        title='Admin'
                        icon={<SettingsSystemDaydreamOutlined />}
                        itemButtonId="bt_ADMIN_015"
                        elements={
                            <List component='div' disablePadding>
                                <NavListItem
                                    indent={true}
                                    title={ROUTE_AUDIT_REPORT.title}
                                    destination={ROUTE_AUDIT_REPORT.withSlash}
                                    icon={<DoneOutline />}
                                    allowed={modules?.includes(ROUTE_AUDIT_REPORT.module)}
                                    itemButtonId="bt_AUDIT_REPORT_010"
                                />
                                <NavListItem
                                    indent={true}
                                    title={ROUTE_USER_REPORT.title}
                                    destination={ROUTE_USER_REPORT.withSlash}
                                    icon={<SummarizeOutlined />}
                                    allowed={modules?.includes(ROUTE_USER_REPORT.module)}
                                    itemButtonId="bt_USER_REPORT_011"
                                />
                                <NavListItem
                                    indent={true}
                                    title={ROUTE_USERS.title}
                                    destination={ROUTE_USERS.withSlash}
                                    icon={<PeopleRoundedIcon />}
                                    allowed={modules?.includes(ROUTE_USERS.module)}
                                    itemButtonId="bt_USERS_012"
                                />
                                <NavListItem
                                    indent={true}
                                    title={ROUTE_ROLES.title}
                                    destination={ROUTE_ROLES.withSlash}
                                    icon={<DoNotTouchRoundedIcon />}
                                    allowed={modules?.includes(ROUTE_ROLES.module)}
                                    itemButtonId="bt_USER_ROLES_013"
                                />
                                <NavListItem
                                    indent={true}
                                    title={ROUTE_ACTIVE_SESSIONS.title}
                                    destination={ROUTE_ACTIVE_SESSIONS.withSlash}
                                    icon={<SettingsSuggest />}
                                    allowed={modules?.includes(ROUTE_ACTIVE_SESSIONS.module)}
                                    itemButtonId="bt_ACTIVE_SESSIONS_014"
                                />
                            </List>
                        }
                    />
                }
                <NavListItem
                    title={ROUTE_HELP.title}
                    destination={ROUTE_HELP.withSlash}
                    icon={<HelpOutlineIcon />}
                    itemButtonId="bt_HELP_015"
                />
            </List>
        </Fragment>
    );
}

export default NavList;