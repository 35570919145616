import { useState, useEffect } from 'react';
import {
    Box,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Content } from '../Enums/contents';
import { ROUTE_EDIT_ADJUSTMENTS } from '../routes/Routes';
import ApprovalSingleSelectDevEx from './components/ApprovalSingleSelectDevEx';
import ClosingAlert from '../components/ClosingAlert';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import { LoadingIndicatorCircle } from "../components/Loading";
import { useAuth } from "../hooks/useAuth";

const {
    Buttons: {
        MUI: {
            Variant: {
                outlined
            }
        }
    }
} = Content;

const FYCostsTableModifiers = ({ selectedRowKey, roleAllowApprove, roleAllowEdit, /*roleAllowDeactivate,*/ rowSelected, onApprovalChange, /*onActivationChange*/ }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [loading, setLoading] = useState()
    const [allowEditing, setAllowEdit] = useState(false);
    const [allowApprove, setAllowApprove] = useState(false);
    const [allowDeactivate, setAllowDeactivate] = useState(false);
    const [selectedBuildingNumber, setSelectedBuildingNumber] = useState(undefined);
    const [approvalOptions, setApprovalOptions] = useState(['Submitted', 'Approved', 'Denied'])

    useEffect(() => {
        handlePermissionsChanges(selectedRowKey)
        setSelectedBuildingNumber(selectedRowKey?.buildingSequenceNo);
    }, [selectedRowKey])

    const handleEditAdjustment = () => {
        var selectedRowData = selectedRowKey
        navigate(ROUTE_EDIT_ADJUSTMENTS.withSlash, {
            state: { selectedRowData }
        });
    }

    const handlePermissionsChanges = (selected) => {
        console.log(selected)
        let deactivated = selected?.IsInactive;
        if (!deactivated) {
            switch (selected?.approvalStatus) {
                case 'Approved': // Logic for approved Adjustments: NO edit, NO approval change, NO Deactivate
                    setAllowApprove(false);
                    //setAllowDeactivate(false);
                    setAllowEdit(false);
                    setApprovalOptions(['Approved'])
                    break;
                case 'Denied': // Logic for denied Adjustments: YES Edit, Approvers and Deactivators can change back to submitted, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove /*|| roleAllowDeactivate*/)
                    setAllowEdit(roleAllowEdit)
                    //setAllowDeactivate(roleAllowDeactivate)
                    setApprovalOptions(['Submitted', 'Denied'])
                    break;
                case 'Submitted': // Logic for submitted. YES Edit, YES Approval Change Allowed, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove /*|| roleAllowDeactivate*/)
                    setAllowEdit(roleAllowEdit)
                    //setAllowDeactivate(roleAllowDeactivate)
                    if (roleAllowApprove) setApprovalOptions(['Submitted', 'Approved', 'Denied'])
                    else setApprovalOptions(['Submitted', 'Denied'])
                    break;
                case 'Pending': // Logic for submitted. YES Edit, YES Approval Change Allowed, YES Deactivate/Reactivate
                    setAllowApprove(roleAllowApprove /*|| roleAllowDeactivate*/)
                    setAllowEdit(roleAllowEdit)
                    //setAllowDeactivate(roleAllowDeactivate)
                    if (roleAllowApprove) setApprovalOptions(['Submitted', 'Approved', 'Denied'])
                    else setApprovalOptions(['Submitted', 'Denied'])
                    break;
                default:
                    setAllowApprove(false)
                    //setAllowDeactivate(false)
                    setAllowEdit(false)
                    setApprovalOptions([])
                    break;
            }
        }
        else {
            // Logic for Deactivated. NO Edit, NO Approval Change allowed (reactivate will set status to "Submitted"), YES reactivate for Approvers or Deactivators
            setAllowApprove(false);
            setAllowEdit(false);
            //setAllowDeactivate(roleAllowApprove || roleAllowDeactivate);
            setApprovalOptions([])
        }
    }

    const handleApprovalChangeClick = async (value) => {
        setLoading(true)
        var editedAdjustment = {
            SecurityCostId: selectedRowKey.id,
            ApprovalStatus: value,
            UserAccount: user.email
        }
        const { Success, Errors } = await PutPostPatchFetch('/v1/SecurityCostBatch/ChangeApproval', 'PUT', editedAdjustment);

        if (Success) {
            setSuccess(true);
            setSuccessMessage("Cost estimate has been successfully " + value)
            setError(false);
            setLoading(false);
            onApprovalChange(value)
            var tempNewKey = selectedRowKey;
            tempNewKey.ApprovalStatus = editedAdjustment.ApprovalStatus
            handlePermissionsChanges(tempNewKey)
        }
        else {
            setSuccess(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors);
            setLoading(false);
        };
    }

    
    return (
        <div>
            <Box sx={{ height: 100, width: '97%', margin: 'auto auto 0 auto' }}>
                {selectedRowKey && !loading &&
                    <>
                    {
                        //allowDeactivate &&
                        //    <Button
                        //        sx={{ mt: 1, ml: 1, pl: 1 }}
                        //        aria-disabled={!rowSelected}
                        //        disabled={!rowSelected}
                        //        onClick={handleActivationChangeClick}
                        //        variant={outlined}
                        //    >
                        //        {deactivateButtonLabel}
                        //    </Button>
                        }
                        {allowApprove &&
                            <ApprovalSingleSelectDevEx
                                approvalOptions={approvalOptions}
                                approvalAllowed={allowApprove}
                                customLabel={selectedBuildingNumber}
                                selectedAdjustment={selectedRowKey}
                                handleSubmit={handleApprovalChangeClick}
                            />
                        }
                    </>
                }
                {loading &&
                    <LoadingIndicatorCircle message="Submitting changes, please wait ..." />
                    }
            </Box>
            <ClosingAlert
                clickableIcon={() => { setSuccess(false) }}
                hideAlert={() => { setSuccess(false) }}
                message={successMessage}
                severity='success'
                visible={success}
            />
            <ClosingAlert
                hideAlert={() => { setError(false) }}
                message={errorMessage}
                severity='warning'
                visible={error}
            />
        </div>
    )
}


export default FYCostsTableModifiers;