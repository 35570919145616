import { useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import { DropDownBox, List, TextBox } from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useAuth } from '../../hooks/useAuth';
import GetFetch from "../../hooks/GetFetch";

const makeWeirdBuildingKeyObject = (list) => {
    if (list === undefined) return [];
    if (list === null) return [];
    if (!list.length > 0) return [];

    return list.map(({ SequenceNumber, RegionNumber }) => { return { RegionNumber, SequenceNumber } });
}
export default function BuildingMultiSelectDevEx({
    value,
    selectedBuildings,
    valueSetter,
    selectedRgns,
    dependentOnRegion,
    allAllowed = true,
    req = true,
}) {
    const [loading, setLoading] = useState(false);
    const [listOfBuildings, setBuildingList] = useState([]);
    const [allBuildings, setAllBuildings] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(makeWeirdBuildingKeyObject(selectedBuildings));
    const [searchValue, setSearchValue] = useState()
    const { user } = useAuth();

    const dropDownBoxRef = useRef(null);
    const selectionMode = "multiple";
    useEffect(() => {
        fetchBuildingNumbers();
    }, [])

    useEffect(() => {
        onRegionsChanged();
    }, [selectedRgns]);

    useEffect(() => {
        console.log("selectedKeys", selectedKeys)
    }, [selectedKeys]);

    const fetchBuildingNumbers = async () => {
        setLoading(true);

        const regionAndUser = {
            RegionNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            UserId: user.email
        };

        if (dependentOnRegion === true) {
            const { Errors, Message, Success } = await PutPostPatchFetch('/v1/BuildSpecReport/BuildingNumbersByRegions', 'POST', regionAndUser);

            if (Success === true) {
                let list = Message;
                if (allAllowed && Message && Message.length > 0) {
                    list = [{ SequenceNumber: "All" }].concat(Message);
                }
                setBuildingList(list);
                setAllBuildings(Message);
            }
            else {
                setBuildingList(null);
            }
        }
        else {
            const { Errors, Message, Success } = await GetFetch('/v1/BuildSpecReport/BuildingNumbers', 'GET');
            if (Success === true) {
                let list = Message;
                if (allAllowed)
                    list = Message && Message.length > 0 ? [{ SequenceNumber: "All" }].concat(Message) : Message;
                setBuildingList(list);
                setAllBuildings(Message);

            }
            else {

                setBuildingList(null);
            }

        }
        setLoading(false);
    };

    const onRegionsChanged = () => {
        if (allBuildings !== undefined) {
            if (allBuildings !== null) {
                if (allBuildings.length > 0) {
                    if (dependentOnRegion && selectedRgns) {
                        let filtered = allBuildings.filter(b => { return selectedRgns.includes(b.RegionNumber) })

                        if (allAllowed) {
                            setBuildingList([{ SequenceNumber: "All" }].concat(filtered))
                        }
                        else {
                            setBuildingList(filtered)
                        }
                        let selected = selectedKeys.filter(b => selectedRgns.includes(b.RegionNumber))
                        setSelectedKeys(selected)
                    }
                    else {
                        setBuildingList(allBuildings)
                        setSelectedKeys([])
                    }
                }
            }
        }
    }

    const onSearchValueChanged = (e) => {
        if (searchValue !== e.value) {
            let filteredList = []
            let searchedValue = e.value;
            var listOfSequenceNumbers = listOfBuildings.map((i) => i.SequenceNumber);
            var newVal = [];

            let valArray = typeof searchedValue === "string" && searchedValue !== '' ? searchedValue.split(/[\s+,]/) : null;
            if (typeof searchedValue === "string" && searchedValue === '') {
                setSelectedKeys([])
                valueSetter([])
                let filtered = allBuildings;
                if (dependentOnRegion) {
                    filtered = selectedRgns !== null ? allBuildings.filter(b => { return selectedRgns.includes(b.RegionNumber) }) : allBuildings
                }
                // why are we assigning all buildings here?
                if (allAllowed) setBuildingList([{ SequenceNumber: "All" }].concat(filtered))
                else setBuildingList(filtered)
                setSearchValue(e.value)

                return;
            }
            // TODO: assign only within selected regions
            if (valArray && valArray.length > 0) {
                valArray.forEach(element => {
                    let searchedValueUpperCase = element.toUpperCase();
                    if (searchedValueUpperCase === "ALL") {
                        if (allAllowed) filteredList = [{ SequenceNumber: "All" }].concat(allBuildings);
                        else filteredList = allBuildings;
                        newVal = allBuildings;
                    }
                    else {
                        let allowedBuildings = listOfBuildings.filter(building => selectedRgns.find(r => r === building.RegionNumber))
                        if (listOfSequenceNumbers.indexOf(searchedValueUpperCase) > -1) {
                            // There are matches or a match
                            let val = allowedBuildings.filter(x => x.SequenceNumber === searchedValueUpperCase);
                            newVal.push(val[0]);
                        }

                        filteredList = filteredList.concat(allBuildings.filter(x => x.SequenceNumber.toUpperCase().includes(searchedValueUpperCase) && !filteredList.includes(x)))
                    }
                });
            }
            if (newVal.length > 0) {

                setSelectedKeys(newVal);
                valueSetter(newVal);

            }
            let searchListWithinRegions = dependentOnRegion ? filteredList.filter(building => selectedRgns.find(r => r === building.RegionNumber)) : filteredList
            setBuildingList(searchListWithinRegions);
            setSearchValue(e.value)
        }
    };

    const onSelectedItemKeysChange = ({ name, value }) => {
        if (name === 'selectedItemKeys') {
            if (selectionMode !== 'none' || selectedKeys.length !== 0) {
                let newValue = value.map((bldg) => bldg.SequenceNumber);
                if (newValue.includes("All")) {
                    const allBldgs = allBuildings.map((bld) => bld.SequenceNumber);
                    if (allBldgs) {
                        valueSetter(allBldgs);
                        setSelectedKeys(listOfBuildings);
                    }
                }
                else {
                    let selectedList = selectedKeys.map((item) => item.SequenceNumber);
                    if (selectedList.includes("All") && !newValue.includes("All")) {
                        valueSetter([]);
                        setSelectedKeys([]);
                    }
                    else if (newValue && newValue[0] !== 'undefined') {
                        setSelectedKeys(value);
                        valueSetter(newValue);
                    }
                }
            }
        }
    };

    return (
        <div>
            {(loading && !listOfBuildings) && (
                <LoadingIndicatorCircle message="Loading Building Numbers, please wait ..." />
            )}
            {listOfBuildings &&
                (
                    <FormControl sx={{ maxWidth: 200, marginRight: 5 }}>
                        <InputLabel id="fy-autocomplete-label"></InputLabel>
                        <div id="myDropDownBox" role="combobox" aria-haspopup="listbox" aria-expanded="false"
                            aria-live="polite" aria-owns="listOfRegions">
                            <DropDownBox
                                label="Building(s)"
                                labelMode="floating"
                                height={56}
                                width={230}
                                value={value}
                                valueExpr="SequenceNumber"
                                displayExpr="SequenceNumber"
                                dataSource={listOfBuildings}
                                ref={dropDownBoxRef}
                                tabIndex={0}
                                disabled={dependentOnRegion && selectedRgns && selectedRgns.length == 0}

                            >
                                <>
                                    <TextBox
                                        value={searchValue ?? ""}
                                        onValueChanged={onSearchValueChanged}
                                        placeholder="Search..."
                                        showClearButton={true}
                                    />
                                    <List
                                        selectedItemKeys={selectedKeys}
                                        showSelectionControls={true}
                                        selectionMode="multiple"
                                        ref={dropDownBoxRef}
                                        displayExpr="SequenceNumber"
                                        onOptionChanged={onSelectedItemKeysChange}
                                        pageLoadMode="scrollBottom"
                                        showScrollbar="always"
                                        useNativeScrolling={false}
                                        height="400px"
                                        dataSource={listOfBuildings}
                                    >
                                    </List>
                                </>
                                {req &&
                                    <Validator
                                        onInitialized={(e) => e.component.validate()}
                                        onOptionChanged={(e) => e.component.validate()}
                                    >
                                        <RequiredRule type='required' />
                                    </Validator>
                                }
                            </DropDownBox>
                        </div>
                    </FormControl>
                )}
        </div>
    );
}
