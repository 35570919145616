import { Switch } from 'devextreme-react';
import './BaseToggle.scss';

/**
 * 
 * @module BaseTextField 
 * This is the base of a BaseToggle for RMS.
 * It uses a Switch (toggle) component, set as the root UI Component.
 * Use this component to toggle values and/or configure functional
 * features.
 * 
 */

export const BaseToggle = (
    /**
     * @param { String } disabled: Disables the Switch from user input when 
     * set to `true`. Optional. Defaults to false.
     * @param { Object } inputAttr: Sets any global HTML attributes to the 
     * component. Optional. Defualts to {}.
     * @param { String } hint: Displays specified text for a hint that appears 
     * when a user pauses action on the component. Optional. Defaults to undefined.  
     * @param { String } name: The value to be assigned to the name attribute of the underlying HTML element. Required for accessablity. Defaults to "".
     * @param { Function } onValueChanged: Accepts a function that updates the 
     * state of the text field with a new value.
     * @param { Boolean } rtlEnabled: Switches the visual representation of the 
     * component from right-to-left. Optional. Defaults to false.
     * @param { String } switchedOffText: Specifies the text displayed when the UI component is switched off.
     * @param { String } switchedOnText: Specifies the text displayed when the UI component is switched on.
     * @param { Number } width: Specifies the width of the component to a value 
     * that is based in pixels. Optional. Defaults to undefined.
     * @param { Boolean } value: Specifies wheter the current state is "On" or
     * "Off". Required. Defaults to false.
     * 
     */
    {
        disabled,
        elementAttribute,
        hint,
        name,
        rightToLeftEnabled,
        switchedOffText,
        switchedOnText,
        value,
        valueUpdatedByUser,
        width
    }
) => {
    return (
        <span className="inline__span--textbox-wrapper">
            <Switch 
                disabled={disabled}
                elementAttr={elementAttribute}
                hint={hint}
                name={name}
                onValueChanged={valueUpdatedByUser}
                rtlEnabled={rightToLeftEnabled}
                switchedOffText={switchedOffText}
                switchedOnText={switchedOnText}
                width={width}
                value={value}
            />
        </span>
    );
}