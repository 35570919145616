import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    FilterRow,
    MasterDetail
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import InfoIconComponent, { InfoIconDevExtremeComponent } from '../../components/CustomInfoIcon';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';
import { RowExpander } from '../../components/RowExpander/RowExpander';

const AdjustmentChanges = ({ data }) => {
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {

            const mappedData = data.map((element) => {
                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    DateChanged: (element.DateChanged === null || element.DateChanged === "")
                        ? "N/A" : dayjs(element.DateChanged).utc().format("MM/DD/YYYY h:mma"),
                    UpdatedDate: element.ChangedValue.UpdatedDate ?? "N/A",
                    UpdatedBy: element.user ?? "N/A",
                    AdjustedAmount: element.ChangedValue.AdjustedAmount ?? "N/A",
                    InitAdjustedAmount: element.InitialValue?.AdjustedAmount ?? "N/A",
                    PSOAmount: element.ChangedValue.PSOAmount ?? "N/A",
                    InitPSOAmount: element.InitialValue?.PSOAmount ?? "N/A",
                    TCMAmount: element.ChangedValue.TCMAmount ?? "N/A",
                    InitTCMAmount: element.InitialValue?.TCMAmount ?? "N/A",
                    BillPeriodStart: element.ChangedValue.BillPeriodStart ?? "N/A",
                    InitBillPeriodStart: element.InitialValue?.BillPeriodStart ?? "N/A",
                    BillPeriodEnd: element.ChangedValue.BillPeriodEnd ?? "N/A",
                    InitBillPeriodEnd: element.InitialValue?.BillPeriodEnd ?? "N/A",
                    Reason: element.ChangedValue.Reason ?? "N/A",
                    InitReason: element.InitialValue?.Reason ?? "N/A",
                    OccupancyAgreementNumber: element.ChangedValue.OccupancyAgreement?.OANumber ?? "N/A",
                    InitOccupancyAgreementNumber: element.InitialValue?.OccupancyAgreement?.OANumber ?? "N/A",
                    ApprovalStatus: element.ChangedValue.ApprovalStatus ?? "N/A",
                    InitApprovalStatus: element.InitialValue?.ApprovalStatus ?? "N/A",
                    BuildingNumber: element.ChangedValue.OccupancyAgreement?.Building?.BuildingSequenceNumber ?? "N/A",
                    InitBuildingNumber: element.InitialValue?.OccupancyAgreement?.Building?.BuildingSequenceNumber ?? "N/A",
                    Region: element.ChangedValue.OccupancyAgreement?.Building?.RegionNumber ?? "N/A",
                    InitRegion: element.InitialValue?.OccupancyAgreement?.Building?.RegionNumber ?? "N/A",
                    IsInactive: element.ChangedValue.IsInactive?"Inactive":"Active",
                    InitIsInactive: element.InitialValue?.IsInactive ? "Inactive" : "Active",
                    PreviousEntry: element.InitialValue,
                    CurrentEntry: element.ChangedValue
                }

            });
            return mappedData;
        }
    });
    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }
    return (
        <div>
            <FOUOheader
                dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}
                heading={"Audit - Adjustment Changes"}
                userEmail={user.email}
            />
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={e => ExportGrid(e, "Audit_Report", wrapPdfCell(), pdfFont())}

            >
                <Column
                    type='detailExpand'
                    cellRender={(data) => RowExpander(data, "BuildingNumber")}
                />
                <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                <Column dataField="DateChanged" caption="Date Changed (UTC)" />
                <Column dataField="ChangeType" caption="Change Type" />
                <Column dataField="UpdatedBy" caption="Changed By" />
                <Column dataField="ApprovalStatus" caption="Approval Status" />
                <Column dataField="Region" caption="Region" />
                <Column dataField="BuildingNumber" caption="Building Number" />
                <Column dataField="OccupancyAgreementNumber" caption="Occupancy Agreement Number" />
                <Column dataField="AdjustedAmount" caption="Adjusted Amount" />
                <Column dataField="PSOAmount" caption="PSOAmount" />
                <Column dataField="TCMAmount" caption="TCM Amount" />
                <Column dataField="BillPeriodStart" caption="Bill Period Start" />
                <Column dataField="BillPeriodEnd" caption="Bill Period End" />
                <Column dataField="Reason" caption="Reason" />

                {/* <FilterRow visible={true} /> */}
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
                <MasterDetail
                    enabled={true}
                    render={({ data }) => RenderTemplate(data)}
                />
            </DataGrid>
            <FOUOfooter />
        </div>
    )
}

const valueChanged = (previous, current) => {
    return (JSON.stringify(previous) !== JSON.stringify(current));
}

const changedComponent = (value) => {
    return (
        <>
            {value}
            <InfoIconComponent item={value} key={value} />
        </>
    );
}

const renderGridCell = (cellInfo) => {

    if (cellInfo.rowType === 'data') {
        var prev = cellInfo.data.PreviousEntry;
        var current = cellInfo.data.CurrentEntry;
        var cellComponent = <></>;
        /*
                            AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    DateChanged: (element.DateChanged === null || element.DateChanged === "")
                        ? "N/A" : dayjs(element.DateChanged).utc().format("MM/DD/YYYY h:mma"),
                    UpdatedDate: element.ChangedValue.UpdatedDate ?? "N/A",
                    UpdatedBy: element.user ?? "N/A",
                    AdjustedAmount: element.ChangedValue.AdjustedAmount ?? "N/A",
                    InitAdjustedAmount: element.InitialValue?.AdjustedAmount ?? "N/A",
                    PSOAmount: element.ChangedValue.PSOAmount ?? "N/A",
                    InitPSOAmount: element.InitialValue?.PSOAmount ?? "N/A",
                    TCMAmount: element.ChangedValue.TCMAmount ?? "N/A",
                    InitTCMAmount: element.InitialValue?.TCMAmount ?? "N/A",
                    BillPeriodStart: element.ChangedValue.BillPeriodStart ?? "N/A",
                    InitBillPeriodStart: element.InitialValue?.BillPeriodStart ?? "N/A",
                    BillPeriodEnd: element.ChangedValue.BillPeriodEnd ?? "N/A",
                    InitBillPeriodEnd: element.InitialValue?.BillPeriodEnd ?? "N/A",
                    Reason: element.ChangedValue.Reason ?? "N/A",
                    InitReason: element.InitialValue?.Reason ?? "N/A",
                    OccupancyAgreementNumber: element.ChangedValue.OccupancyAgreement?.OANumber ?? "N/A",
                    InitOccupancyAgreementNumber: element.InitialValue?.OccupancyAgreement?.OANumber ?? "N/A",
                    ApprovalStatus: element.ChangedValue.ApprovalStatus ?? "N/A",
                    InitApprovalStatus: element.InitialValue?.ApprovalStatus ?? "N/A",
                    BuildingNumber: element.ChangedValue.OccupancyAgreement?.Building?.BuildingSequenceNumber ?? "N/A",
                    InitBuildingNumber: element.InitialValue?.OccupancyAgreement?.Building?.BuildingSequenceNumber ?? "N/A",
                    Region: element.ChangedValue.OccupancyAgreement?.Building?.RegionNumber ?? "N/A",
                    InitRegion: element.InitialValue?.OccupancyAgreement?.Building?.RegionNumber ?? "N/A",
                    PreviousEntry: element.InitialValue,
                    CurrentEntry: element.ChangedValue
        */

        switch (cellInfo.column.dataField) {
            case 'InitApprovalStatus': cellComponent = valueChanged(prev.ApprovalStatus, current.ApprovalStatus) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitRegion': cellComponent = valueChanged(prev.OccupancyAgreement?.Building?.RegionNumber, current.OccupancyAgreement?.Building?.RegionNumber) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitBuildingNumber': cellComponent = valueChanged(prev.OccupancyAgreement?.Building?.BuildingSequenceNumber, current.OccupancyAgreement?.Building?.BuildingSequenceNumber) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitOccupancyAgreementNumber': cellComponent = valueChanged(prev.OccupancyAgreement?.OANumber, current.OccupancyAgreement?.OANumber) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitAdjustedAmount': cellComponent = valueChanged(prev.AdjustedAmount, current.AdjustedAmount) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitPSOAmount': cellComponent = valueChanged(prev.PSOAmount, current.PSOAmount) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitTCMAmount': cellComponent = valueChanged(prev.TCMAmount, current.TCMAmount) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitBillPeriodStart': cellComponent = valueChanged(prev.BillPeriodStart, current.BillPeriodStart) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitBillPeriodEnd': cellComponent = valueChanged(prev.BillPeriodEnd, current.BillPeriodEnd) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
            case 'InitReason': cellComponent = valueChanged(prev.Reason, current.Reason) ?
                changedComponent(cellInfo.value) : cellInfo.value;
            case 'InitIsInactive': cellComponent = valueChanged(prev.IsInactive, current.IsInactive) ?
                changedComponent(cellInfo.value) : cellInfo.value;
                break;
        }
        return cellComponent;
        
    }
}

const RenderTemplate = (values) => {
    return (
        <>
            {
                (values.PreviousEntry === null || values.ChangeType === 'Insert') ?
                    <>No Previous History, record was inserted.</>
                    :
                    <DataGrid
                        dataSource={[values]}
                        showBorders={true}
                        columnAutoWidth={true}
                    >

                        <Column dataField="InitIsInactive" caption="Activation Status" cellRender={renderGridCell} />
                        <Column dataField="InitApprovalStatus" caption="Approval Status" cellRender={renderGridCell} />
                        <Column dataField="InitRegion" caption="Region" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingNumber" caption="Building Number" cellRender={renderGridCell} />
                        <Column dataField="InitOccupancyAgreementNumber" caption="Occupancy Agreement Number" cellRender={renderGridCell} />
                        <Column dataField="InitAdjustedAmount" caption="Adjusted Amount" cellRender={renderGridCell} />
                        <Column dataField="InitPSOAmount" caption="PSOAmount" cellRender={renderGridCell} />
                        <Column dataField="InitTCMAmount" caption="TCM Amount" cellRender={renderGridCell} />
                        <Column dataField="InitBillPeriodStart" caption="Bill Period Start" cellRender={renderGridCell} />
                        <Column dataField="InitBillPeriodEnd" caption="Bill Period End" cellRender={renderGridCell} />
                        <Column dataField="InitReason" caption="Reason" cellRender={renderGridCell} />

                    </DataGrid >
            }
        </>
    );
}
export default AdjustmentChanges;
