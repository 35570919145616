import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { ROUTE_CUSTOMERS } from '../routes/Routes.js';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import IsNullOrEmpty from '../components/IsNullOrEmpty';
import US_StatesSelectField from './components/US_StatesSelectField';
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from "../components/BaseButton/BaseButton";
import { BaseTextField } from '../components/BaseTextField/BaseTextField';
import { Content } from '../Enums/contents';
import CustomerBuildingSelect from './CustomerBuildingSelect';
import { BaseCheckBox } from '../components/BaseCheckBox/BaseCheckBox.js';
import "./CustomersUpdate.scss"
import "./../sass/_utilities.scss";


const {
    Alerts: {
        CustomerSavedSuccessful
    },
    Buttons: {
        cancel,
        submit,
        selectBuildingsLabel,
        MUI: {
            Severity: {
                success,
                warning,
                info
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _floating
        },
        Input_TextField_Labels: {
            addressOneLabel,
            addressTwoLabel,
            cityNameLabel,
            zipCodeLabel,
            customer_labels: {
                agencyNameLabel,
                abNameLabel,
                abShortNameLabel,
                abCodeLabel,
                billedABCodeLabel,
                actualABCodeLabel,
                agencyLocationCodeLabel,
            }
        },
        Input_Validation_Messages: {
            abCodeValidationMessage
        }
    },
    Masks: {
        zipCodeInvalidMessage,
        longZipCodeInvalidMessage,
        zipCodeMask
    }
} = Content;

const CustomersAdd = () => {


    const { user } = useAuth();
    const [_agencyName, set_AgencyName] = useState("");
    const [_abName, set_ABName] = useState("");
    const [_abShortName, set_ABShortName] = useState("");
    const [_abCode, set_ABCode] = useState("");
    const [_billedABCode, set_BilledABCode] = useState("");
    const [_actualABCode, set_ActualABCode] = useState("");
    const [_agencyLocationCode, set_AgencyLocationCode] = useState("");
    const [_tas, set_tas] = useState("");
    const [_betc, set_betc] = useState("");
    const [_pocFirstName, set_pocFirstName] = useState("");
    const [_pocLastName, set_pocLastName] = useState("");
    const [_fundingDocNumber, set_fundingDocNumber] = useState("");
    const [_sendIpac, set_sendIpac] = useState(false);
    const [_recieveInvoiceSummary, set_recieveInvoiceSummary] = useState(false);
    const [_address1, set_Address1] = useState("");
    const [_address2, set_Address2] = useState("");
    const [_zipCode, set_ZipCode] = useState("");
    const [_state, set_State] = useState("");
    const [_cityName, set_CityName] = useState("");

    const [_selectedBuildingList, set_SelectedBuildingList] = useState([])
    const [_selectedOas, set_SelectedOas] = useState([])

    const [canSubmitForm, set_CanSubmitForm] = useState(false);
    const [submitting, set_submitting] = useState(false);

    const [customerSaved, setCustomerSaved] = useState(false);
    const [customerSaveFail, setCustomerSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [codeMismatchAlert, setCodeMismatchAlert] = useState(false);

    /**
     * @todo: Export this function for global sharing
    */
    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_CUSTOMERS.withSlash);
    const title = "Add a Customer";
    const lblCss = {
        fontWeight: 'bold',
        fontSize: 10
    }
    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        enableFormSubmission();
    }, [
        _agencyName,
        _abName,
        _abShortName,
        _abCode,
        _billedABCode,
        _actualABCode,
        _agencyLocationCode,
        _address1,
        _address2,
        _cityName,
        _state,
        _zipCode,
        _selectedBuildingList,
        _tas,
        _betc,
        _pocFirstName,
        _pocLastName
    ]);

    useEffect(() => {
        if (isValidCode(_billedABCode) && isValidCode(_actualABCode)) {
            const billedStart = _billedABCode.slice(0, 2)
            const actualStart = _actualABCode.slice(0, 2)

            setCodeMismatchAlert(billedStart != actualStart)
        }
    },[_billedABCode,_actualABCode])

    const enableFormSubmission = () => {

        const result = (
            isValidName(_agencyName)
            && isValidName(_abName)
            && isValidABShortName(_abShortName)
            && isValidCode(_abCode)
            && isValidCode(_billedABCode)
            && isValidCode(_actualABCode)
            && isValidLocationCode(_agencyLocationCode)
            && validateFields(_address1)
            && validAddress(_address1)
            && validAddress(_address2)
            && validCity(_cityName)
            && validZipCode(_zipCode)
            && isValidBuildingList(_selectedBuildingList)
            && validateBetc()
            && validateTas()
            && _pocFirstName != ""
            && _pocLastName != ""
            && validateFundingDocNumber()
           
        );

        set_CanSubmitForm(result);
    };

    const validateFields = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        };

        return true;
    }
    const validAddress = (value) => {
        const reg = /^[a-z0-9. ]+$/i
        if (IsNullOrEmpty(value) || (reg.test(value) && value.length <= 35)) return true;
        else return false;
    }
    const validCity = (value) => {
        const reg = /^[a-z ]+$/i
        if (reg.test(value) && value.length <= 23) return true;
        else return false;
    }

    const validateTas = () => {
        const reg = /^[a-z0-9]+$/i
        return _tas != "" && _tas.length <= 22 && reg.test(_tas);
    }
    const validZipCode = (value) => {
        if (value.length === 9 || value.length === 5) return true;
        return false;
    };

    const isValidName = (input) => {
        const reg = /^[a-z0-9 ]+$/i
        return (!IsNullOrEmpty(input) && input.length <= 50 && reg.test(input))
    };
    const isValidABShortName = (input) => {
        return (IsNullOrEmpty(input) || input.length <= 55)
    }
    const isValidCode = (input) => {
        if (IsNullOrEmpty(input)) {
            return false;
        }
        if (input.length !== 4) {
            return false;
        }
        if (/^[A-Za-z0-9]*$/.test(input) !== true) {
            return false;
        }
        return true;
    };
    const isValidLocationCode = (input) => {
        const reg = /^[a-z0-9]+$/i
        return (!IsNullOrEmpty(input) && input.length <= 11 && reg.test(input))
    };
    const isValidBuildingList = (input) => {
        return (!IsNullOrEmpty(input) && input.length > 0)
    };

    const handleSubmit = async () => {
        if (!canSubmitForm) {
            console.error('Can\'t be submitted', buildingObject)
            return;
        }
        set_submitting(true);
        const formattedZip = _zipCode.slice(0, 5) + '-' + _zipCode.slice(5)

        const formattedOas = _selectedOas.map(oa => {
            return ({
                "BuildingId": oa.BuildingNumber,
                "EffectiveDate": oa.EffectiveDate?.toISOString().substring(0, 10),
                "ExpirationDate": oa.ExpirationDate?.toISOString().substring(0, 10),
                "OANumber": oa.OccupancyAgreementNumber,
                "TotalRentSqFt": oa.TotalRentSqFt,
                "CreatedBy": user.email
            })
        })

        const customerObject = {
            "AgencyName": _agencyName,
            "AgencyBureauName": _abName,
            "AgencyBureauShortName": _abShortName ?? '',
            "AgencyBureauCode": _abCode,
            "BilledAgencyBureauCode": _billedABCode,
            "ActualAgencyBureauCode": _actualABCode,
            "AgencyLocationCode": _agencyLocationCode,
            "TreasuryAccSymbol": _tas,
            "BusinessEventTypeCode": _betc,
            "CustomerPOCFirstName": _pocFirstName,
            "CustomerPOCLastName": _pocLastName,
            "POCsToRecieveSummary": _recieveInvoiceSummary,
            "SendIPAC": _sendIpac,
            "FundingDocumentNumber": _fundingDocNumber,
            "BureauAddress": {
                "Id": 0,
                "Address1": _address1,
                "Address2": _address2,
                "CityName": _cityName,
                "State": _state,
                "ZipCode": formattedZip
            },
            "CreatedBy": user.email,
            "Buildings": _selectedBuildingList,
            "OccupancyAgreements": formattedOas
        };

        const request = await PutPostPatchFetch('/v1/Customer/AddCustomer', 'POST', customerObject);
        const { Success, Errors } = request;

        if (request === undefined) {
            setCustomerSaved(false);
            setCustomerSaveFail(true);
            setErrorMessage("Something went wrong... Please correct your inputs and try again");
            set_submitting(false)
            return;
        }

        if (Success) {
            setCustomerSaved(true);
            setCustomerSaveFail(false);
        }
        else {
            setCustomerSaved(false);
            setCustomerSaveFail(true);
            setErrorMessage(Errors[0].Message);
            console.log("What went wrong:", Errors);
            set_submitting(false)
        }

    }

    const handleSelectedBuildingsChange = (value) => {
        set_SelectedBuildingList(value)
    }
    const validateBetc = () => {
        const reg = /^[a-z]+$/i
        return _betc != "" && _betc.length <= 8 && reg.test(_betc);
    };
    const validatePocName = (name) => {
        const reg = /^[a-z ]+$/i
        return name != "" && name.length <= 50 && reg.test(name);
    };

    const validateFundingDocNumber = () => {
        const reg = /^[a-z0-9-]+$/i
        if (_fundingDocNumber.length > 0)
            return reg.test(_fundingDocNumber)  && _fundingDocNumber.length <= 20;
        return true;
    }
    return (
        <div>
            <h1>{title}</h1>
            <form className="inline__div--wrapper" method="post">
                <BaseTextField
                    inputAttribute={{ "aria-label": "Agency Name" }}
                    isValueValid={isValidName(_agencyName)}
                    hoverStateEnabled={false}
                    customValidationMessage="Up to 50 alphanumeric characters, no special characters allowed. Example: Department of State."
                    label={agencyNameLabel}
                    labelMode={_floating}
                    maxLength={50}
                    value={_agencyName}
                    valueUpdatedByUser={
                        ({ value }) => set_AgencyName(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Agency Bureau Name" }}
                    isValueValid={isValidName(_abName)}
                    hoverStateEnabled={false}
                    customValidationMessage="Max 50 alphanumeric characters, no special characters allowed. Example: Department of State"
                    label={abNameLabel}
                    labelMode={_floating}
                    maxLength={50}
                    value={_abName}
                    valueUpdatedByUser={
                        ({ value }) => set_ABName(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Agency Bureau Short Name" }}
                    isValueValid={isValidABShortName(_abShortName)}
                    isRequired={false}
                    hoverStateEnabled={false}
                    label={abShortNameLabel}
                    labelMode={_floating}
                    maxLength={55}
                    value={_abShortName}
                    valueUpdatedByUser={
                        ({ value }) => set_ABShortName(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Agency Bureau Code" }}
                    isValueValid={isValidCode(_abCode)}
                    customValidationMessage="Should be 4 digit Numbers only. Example: 1234"
                    hoverStateEnabled={false}
                    label={abCodeLabel}
                    labelMode={_floating}
                    maxLength={4}
                    value={_abCode}
                    valueUpdatedByUser={
                        ({ value }) => set_ABCode(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Billed Agency Bureau Code" }}
                    isValueValid={isValidCode(_billedABCode)}
                    customValidationMessage="Should be 4 digit Numbers only. Example: 1234"
                    hoverStateEnabled={false}
                    label={billedABCodeLabel}
                    labelMode={_floating}
                    maxLength={4}
                    value={_billedABCode}
                    valueUpdatedByUser={
                        ({ value }) => set_BilledABCode(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Actual Agency Bureau Code" }}
                    isValueValid={isValidCode(_actualABCode)}
                    customValidationMessage="Should be 4 digit Numbers only. Example: 1234"
                    hoverStateEnabled={false}
                    label={actualABCodeLabel}
                    labelMode={_floating}
                    maxLength={4}
                    value={_actualABCode}
                    valueUpdatedByUser={
                        ({ value }) => set_ActualABCode(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Agency Location Code" }}
                    isValueValid={isValidLocationCode(_agencyLocationCode)}
                    customValidationMessage="Max 10 alphanumeric characters, no special characters allowed. Example: 42018675"
                    hoverStateEnabled={false}
                    label={agencyLocationCodeLabel}
                    labelMode={_floating}
                    maxLength={10}
                    value={_agencyLocationCode}
                    valueUpdatedByUser={
                        ({ value }) => set_AgencyLocationCode(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Address One" }}
                    isValueValid={validateFields(_address1) && validAddress(_address1)}
                    customValidationMessage='Max 35 alphanumeric characters, no special characters (except ".") allowed. Example: 123 Main St.'
                    label={addressOneLabel}
                    labelMode={_floating}
                    value={_address1}
                    maxLength={35}
                    valueUpdatedByUser={
                        ({ value }) => set_Address1(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Address Two" }}
                    isValueValid={validAddress(_address2)}
                    customValidationMessage='Max 35 alphanumeric characters, no special characters (except ".") allowed.'
                    label={addressTwoLabel}
                    labelMode={_floating}
                    value={_address2}
                    maxLength={35}
                    valueUpdatedByUser={
                        ({ value }) => set_Address2(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "City Name" }}
                    isValueValid={validCity(_cityName)}
                    customValidationMessage='Max 23 characters. No special characters or numbers allowed. Example: Boston.'
                    maxLength={23}
                    label={cityNameLabel}
                    labelMode={_floating}
                    value={_cityName}
                    valueUpdatedByUser={
                        ({ value }) => set_CityName(value)
                    }
                />
                <US_StatesSelectField
                    enableSearch={true}
                    isVisible={true}
                    optionUpdatedByUser={({ selectedItem }) => set_State(selectedItem)}
                    value={_state}
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Zip Code" }}
                    isValueValid={validZipCode(_zipCode)}
                    label={zipCodeLabel}
                    labelMode={_floating}
                    mask={"00000-0000"}
                    maskCharacter={"*"}
                    maskInvalidMessage={longZipCodeInvalidMessage}
                    maxLength={10}
                    value={_zipCode}
                    valueUpdatedByUser={
                        (e) => {
                            set_ZipCode(e.value)
                        }
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "TAS-Treasury Account Symbol" }}     
                    isValueValid={validateTas()}          
                    customValidationMessage="Max 22 characters allowed. Cannot include special characters. Example: 123X4567891X123456"
                    label="TAS-Treasury Account Symbol *"
                    labelMode={_floating}       
                    value={_tas}
                    maxLength={22}
                    valueUpdatedByUser={
                        (e) => {
                            set_tas(e.value)
                        }
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "BETC-Business Event Type Code" }}
                    isValueValid={validateBetc()}
                    customValidationMessage="Max 8 characters. Cannot include special characters or numbers. Example: COLL"
                    label={"BETC-Business Event Type Code *"}
                    maxLength={8 }
                    labelMode={_floating}
                    value={_betc}
                    isRequired={true}
                    valueUpdatedByUser={
                        ({ value }) => set_betc(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Customer POC First Name" }}
                    isValueValid={validatePocName(_pocFirstName)}
                    maxLength={50 }
                    customValidationMessage="Max 50 characters. Cannot include special characters or numbers. Example: Jonathan"
                    label={"Customer POC First Name *"}
                    labelMode={_floating}
                    value={_pocFirstName}
                    valueUpdatedByUser={
                        ({ value }) => set_pocFirstName(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Customer POC Last Name" }}
                    isValueValid={validatePocName(_pocLastName)}
                    customValidationMessage="Max 50 characters. Cannot include special characters or numbers. Example: Smith"
                    maxLength={50 }
                    label={"Customer POC Last Name *"}
                    labelMode={_floating}
                    value={_pocLastName}
                    valueUpdatedByUser={
                        ({ value }) => set_pocLastName(value)
                    }
                />
                <BaseTextField
                    inputAttribute={{ "aria-label": "Funding Document Number" }}
                    isValueValid={validateFundingDocNumber()}
                    isRequired={false}
                    label={"Funding Document Number"}
                    labelMode={_floating}
                    customValidationMessage="Max 20. Cannot include special characters (except for hyphen)."
                    value={_fundingDocNumber}
                    valueUpdatedByUser={
                        ({ value }) => set_fundingDocNumber(value)
                    }
                />
                <span className="inline__span--textbox-wrapper" style={lblCss}>
                    Document # Formats: <br /> MIPR (12345678) <br /> <span>GINV (X1234-567-890-111222)</span> <br /><span>SWA (SWA-R2023FRBY124)</span>
                </span>
                <br />
           
                <BaseCheckBox
                    elementAttr={
                        { "aria-label": "Send IPAC" }
                    }
                    onCheckedValueChanged={
                        ({ value }) => set_sendIpac(value)
                    }
                    text="Send IPAC"
                    value={_sendIpac}
                />
                <BaseCheckBox 
                    elementAttr={
                        { "aria-label": "POCs To Recieve Invoice Summary" }
                    }
                    onCheckedValueChanged={
                        ({ value }) => set_recieveInvoiceSummary(value)
                    }
                    text="POCs To Recieve Invoice Summary"
                    value={_recieveInvoiceSummary}
                />
                
            </form>
           
            <CustomerBuildingSelect
                value={_selectedBuildingList}
                valueSetter={handleSelectedBuildingsChange}
                setOaList={(oas) => { set_SelectedOas(oas) }}
            />
            <br />
            <br />
            {!submitting ?
                <div className="inline__div--wrapper" >
                    <BaseButton
                        label={cancel}
                        variant={outlined}
                        onClick={navigateToRoute}
                    />
                    <BaseButton
                        ariaDisabled={!canSubmitForm ? true : false}
                        disabled={!canSubmitForm}
                        label={submit}
                        onClick={handleSubmit}
                        variant={outlined}
                    />
                </div>
                :<>Submitting...</>
            }
            <ClosingAlert
                clickableIcon={navigateToRoute}
                enableAlertIcon={true}
                message={CustomerSavedSuccessful}
                severity={success}
                visible={customerSaved}
            />
            <ClosingAlert
                hideAlert={() => { setCustomerSaveFail(false) }}
                message={errorMessage}
                severity={warning}
                visible={customerSaveFail}
            />
            <ClosingAlert
                hideAlert={() => { setCodeMismatchAlert(false) }}
                message={"There appears to be a mismatch between the billed and actual agency bureau codes. If this is correct, please ignore this message and continue."}
                severity={info}
                visible={codeMismatchAlert}
            />
        </div >
    );
}

export default CustomersAdd;
