import {
    useState,
} from 'react';
import Button from '@mui/material/Button';
import {
    ButtonGroup
    , FormControl
    , FormGroup
    , TextField
    , Typography
} from '@mui/material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import GetFetch from '../../hooks/GetFetch';
import {
    DateField,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import dayjs from 'dayjs';
import ClosingAlert from '../../components/ClosingAlert';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess();

const dateFieldSx = {
    width: 140
    , mr: 1
};

const SearchButtonGroupAudit = ({ setter }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [changedBy, setChangedBy] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [infoMessage, setInfoMessage] = useState(null);
    const invalidInputedDates = `Invalid search criteria, please fix your search criteria. (Dates cannot exceed today's date: ${dayjs().format('MM-DD-YYYY')})`;
    const invalidDateRange = "Your start date is greater than your end date. Please add a valid date range, or clear your inputs.";

    const DateIsValid = (value) => {
        // can be empty, but not partial
        if (value === undefined) return true;
        if (value === null) return true;
        if (value.$d === undefined) return true;
        if (value.$d === null) return true;
        if (value.format("MM-DD-YYYY").trim() === 'Invalid Date') return false;
        let todayJs = dayjs();
        if (value.$d > todayJs || value.$y < 1900) return false;

        return true;
    }

    const handleSearch = async () => {
        setter(null);

        let start = startDate && startDate.format("MM-DD-YYYY").trim() !== 'Invalid Date' ? startDate.format("MM-DD-YYYY") : '';
        let end = endDate && endDate.format("MM-DD-YYYY").trim() !== 'Invalid Date' ? endDate.format("MM-DD-YYYY") : '';
        let offset = (new Date()).getTimezoneOffset();
        let queryIt = false;

        if (DateIsValid(startDate) && DateIsValid(endDate)) {
            if ((startDate && endDate) && startDate.$d > endDate.$d) {
                start = '';
                end = '';
                setInfoMessage(invalidDateRange);
                setShowInfo(true);
            }
            else {
                // if you make it here, your input is accepted
                queryIt = true;
                setInfoMessage(null);
                setShowInfo(false);
            }

        }
        else {
            start = '';
            end = '';
            setInfoMessage(invalidInputedDates);
            setShowInfo(true);
        }

        if (!queryIt) return;
        //Adjust input dates for UTC dates
        
        if (dayjs(end).isValid() && offset > 0) {
            end = dayjs(end).add(1, 'day').format("MM-DD-YYYY");
        };

        if (dayjs(start).isValid() && offset < 0) {
            start = dayjs(start).subtract(1, 'day').format("MM-DD-YYYY");
        };

        let changedByQueryVar = (changedBy && changedBy != undefined) ? changedBy : ''

        const { Success, Errors, Message } = await GetFetch(
            `/v1/Audit/GetChanges?startDate=${start}&&endDate=${end}&&username=${changedByQueryVar}`
        );

        if (Success) {
            setter(Message);
            setError(false);
            setErrorMessage(null);
        }
        
        if (Errors.length > 0) {
            setError(true);
            setErrorMessage(Errors[0].Message);
            setter(null);
            console.log("What went wrong:", result.Errors);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    return (
        <>
            <FormGroup sx={{ width: '97%', margin: 'auto' }}>
                <Typography
                    variant="body1"
                    component="em"
                    style={{ color: "#06152B" }}
                >
                    Optional search by Date Range and username. Leave field(s) empty to receive all results.
                </Typography>
                <ButtonGroup
                    disableElevation
                    variant="contained"
                    sx={{ my: 1 }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            sx={dateFieldSx}
                            id="start-date"
                            label="Start Date"
                            format="MM-DD-YYYY"
                            disableFuture={true}
                            value={startDate}
                            onChange={(newValue) => { setStartDate(newValue) }}
                            inputProps={{
                                onKeyDown: handleKeyDown
                            }}
                        />
                        <DateField
                            id="end-date"
                            sx={dateFieldSx}
                            label="End Date"
                            format="MM-DD-YYYY"
                            disableFuture={true}
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue) }}
                            inputProps={{
                                onKeyDown: handleKeyDown
                            }}
                        />
                    </LocalizationProvider>
                    <FormControl variant="filled" >
                        <div style={{ marginRight: '10px' }}>
                            <TextField
                                id="username"
                                label="Changed By"
                                value={changedBy}
                                onChange={e => setChangedBy(e.target.value)}
                                inputProps={{
                                    onKeyDown: handleKeyDown
                                }}
                            />
                        </div>
                    </FormControl>
                    <Button
                        id="submit-button"
                        variant="contained"
                        onClick={handleSearch}
                        startIcon={<QueryStatsRoundedIcon />}
                    >
                        Find Changes
                    </Button>
                    <Button
                        id="clear-button"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                            setChangedBy('');
                        }}
                        sx={{
                            mx: 2
                        }}
                        startIcon={<CleaningServicesRoundedIcon />}
                    >
                        Clear Inputs
                    </Button>
                </ButtonGroup>
                <ClosingAlert
                    severity="warning"
                    message={infoMessage}
                    visible={showInfo}
                    hideAlert={() => { setShowInfo(false) }}
                />
                <ClosingAlert
                    severity="error"
                    message={errorMessage}
                    visible={error}
                    hideAlert={() => { setError(false) }}
                />
            </FormGroup>

        </>
    );
}

export default SearchButtonGroupAudit;