import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useRef, useState } from "react";
import { useEffect } from "react";
import GetFetch from "../../hooks/GetFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import { DropDownBox, List, TextBox } from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useAuth } from "../../hooks/useAuth";


export default function RegionMultiSelectDevEx({ value, valueSetter, allAllowed = true }) {
    const [loading, setLoading] = useState(false);
    const [listOfRegions, setListOfRegions] = useState([]);
    const [regions, setAllRegions] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(value ?? []);
    const {user} = useAuth()

    useEffect(() => {
        fetchRegions();
    }, []);
    const fetchRegions = async () => {
        setLoading(true);
        const result = await GetFetch('/v1/Region');
        const { Success, Message, Errors } = result;
        if (Success) {
            var filteredRegions = Message.filter(r=>user.Regions?.includes(r.RegionNumber))

            var displayRegions = [];
            if (allAllowed) displayRegions = [{ RegionNumber: "All" }].concat(filteredRegions);
            else displayRegions = filteredRegions;
            setListOfRegions(displayRegions);
            setAllRegions(filteredRegions);
        }
        else {
            console.log("What went wrong:", Errors);
        }
        setLoading(false);
    };

    const dropDownBoxRef = useRef(null);
    const selectionMode = "multiple";
    const onSearchValueChanged = (e) => {
        let searchedVal = e.value;
        var list = listOfRegions.map((reg) => reg.RegionNumber);
        var newVal = [];

        let valArray = typeof searchedVal === "string" && searchedVal !== '' ? searchedVal.split(/[\s+,]/) : null;
        if (typeof searchedVal === "string" && searchedVal === '') {
            setSelectedKeys([])
            valueSetter([])
            return;
        }

        if (valArray && valArray.length > 0) {
            valArray.forEach(element => {
                var int = parseInt(element);

                if (list.indexOf(int) >= 0) {
                    let val = listOfRegions.filter(x => x.RegionNumber === int);
                    newVal.push(val[0]);
                }
                if (element.toUpperCase() === "ALL") {
                    newVal = listOfRegions
                }
            });
        }
        if (newVal.length > 0) {

            setSelectedKeys(newVal);
            let vals = newVal.map(x => x.RegionNumber);
            valueSetter(vals);

        }

    };
    const contentRender = (e) => {
        return (
            <React.Fragment>
                <TextBox
                    value={value.length < 1 ? "":value.toString()}
                    onValueChanged={onSearchValueChanged}
                    placeholder="Search..."
                />
                <List
                    selectedItemKeys={selectedKeys}
                    showSelectionControls={true}
                    selectionMode="multiple"
                    ref={dropDownBoxRef}
                    displayExpr="RegionNumber"
                    onOptionChanged={onSelectedItemKeysChange}

                    dataSource={listOfRegions}
                >
                </List>
            </React.Fragment>
        )
    };
    const onSelectedItemKeysChange = ({ name, value }) => {

        if (name === 'selectedItemKeys') {
            if (selectionMode !== 'none' || selectedKeys.length !== 0) {

                let newValue = value.map((reg) => reg.RegionNumber);
                if (newValue.includes("All")) {
                    const allRegions = regions.map((reg) => reg.RegionNumber);
                    if (allRegions) {
                        valueSetter(allRegions);
                        setSelectedKeys(listOfRegions);
                    }
                }
                else {
                    let selectedList = selectedKeys.map((item) => item.RegionNumber);
                    if (selectedList.includes("All") && !newValue.includes("All")) {
                        valueSetter([]);
                        setSelectedKeys([]);
                    }
                    else {
                        if (newValue && newValue[0] !== 'undefined') {
                            setSelectedKeys(value);
                            valueSetter(newValue);
                        }

                    }
                }

            }
        }
    };

    return (
        <div>
            {(loading && !listOfRegions) && (
                <LoadingIndicatorCircle message="Loading Regions, please wait ..." />
            )}
            {
                listOfRegions &&
                (
                    <FormControl sx={{ width: 150 }}>
                        <InputLabel id="fy-autocomplete-label"></InputLabel>
                        <div id="RegionDropDownBox" role="combobox" aria-haspopup="listbox" aria-expanded="false" aria-required="true"
                            aria-live="polite" aria-owns="listOfRegions">
                            <DropDownBox
                                label="Region(s) *"
                                labelMode="floating"
                                height={56}
                                width={150}
                                value={value}
                                valueExpr="RegionNumber"
                                displayExpr="RegionNumber"
                                dataSource={listOfRegions}
                                ref={dropDownBoxRef}
                                tabIndex={0}
                                contentRender={contentRender}
                            // isValid = {value.ProductCodeId.length > 0}
                            >
                                <Validator
                                    onInitialized={(e) => e.component.validate()}
                                    onOptionChanged={(e) => e.component.validate()}
                                >
                                    <RequiredRule type='required' />
                                </Validator>

                            </DropDownBox>
                        </div>
                    </FormControl>
                )}
        </div>
    );
}
