import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTE_REGION_AND_BUILDING_DATA, ROUTE_EDIT_BUILDING } from '../routes/Routes.js';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import SingleRegionSelectField from './components/SingleRegionSelectField/SingleRegionSelectField';
import IsNullOrEmpty from '../components/IsNullOrEmpty';
import US_StatesSelectField from './components/US_StatesSelectField';
import BuildingStatusSelectField from './components/BuildingStatusSelectField';
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from "../components/BaseButton/BaseButton";
import { BaseTextField } from '../components/BaseTextField/BaseTextField';
import { Content } from '../Enums/contents';
import { BaseNumberBox } from '../components/BaseNumberBox/BaseNumberBox';
import { LoadingIndicatorPageLength } from '../components/Loading.js';
import "./BuildingsUpdate.scss"
import "./../sass/_utilities.scss";


const {
    Alerts: {
        BuildingSavedSuccssful
    },
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _floating
        },
        Input_NumberBox_Label: {
            adjustedRentSquareFootageLabel,
            adjustedUsageSquareFootageLabel,
            ansiRentSquareFootageLabel,
            ansiUsableSquareFootageLabel,
            communityJointUsageSquareFootage,
            jointUseSquareFootageLabel,
            leaseJointUseSquareFootageLabel,
            rentUsageFactorLabel
        },
        Input_TextField_Labels: {
            addressOneLabel,
            addressTwoLabel,
            buildingNameLabel,
            buildingFacilityLabel,
            buildingSequenceNumberLabel,
            cityNameLabel,
            congressionalRepresentativeLabel,
            zipCodeLabel
        },
        Input_SelectField_Labels: {
            statusLabel,
            singleRegionLabel
        }
    },
    Masks: {
        zipCodeInvalidMessage,
        zipCodeMask
    },
    NummberFormatting: {
        decimalFormat
    }
} = Content;

const BuildingsUpdate = () => {
    const {
        state: {
            selectedRowData: {
                BuildingId,
                locationId,
                BuildingNumber,
                name,
                status,
                facility,
                region,
                address,
                congressionalRep,
                jointUse,
                leaseJointUse,
                rentUsageFactor,
                ansiRent,
                ansiUsable,
                adjustedRent,
                adjustedUsage,
                communityJointUse,
                version
            }
        }
    } = useLocation();

    const addressString = address.split(', ');
    let address1, address2, cityName, state, zipCode;

    if (addressString  && addressString.length === 3) {
        [address1, cityName] = addressString;
        [state, zipCode] = addressString[2].trim().split(/\s+/);
        address2 = '';
    };

    if (addressString && addressString.length === 4) {
        [address1, address2, cityName] = addressString;
        [state, zipCode] = addressString[3].trim().split(/\s+/);
    };  
    const { user } = useAuth();
    const [_buildingName, set_BuildingName] = useState(name ?? "");
    const [_facility, set_Facility] = useState(facility ?? "");
    const [_region, set_Region] = useState(region);
    const [_status, set_Status] = useState(status && status.toLowerCase() === 'active' ? 'Active':
                                 status && status.toLowerCase() === 'inactive'? 'Inactive': '');

    const [_address1, set_Address1] = useState(address1 === 'null' ? null : address1?.trim() );
    const [_address2, set_Address2] = useState(address2?.trim());
    const [_zipCode, set_ZipCode] = useState(zipCode === 'null' ? null : zipCode);
    const [_state, set_State] = useState(state === 'null' ? null : state);
    const [_cityName, set_CityName] = useState(cityName === 'null' ? null : cityName?.trim());

    const [_adjustedRentSquareFeet, set_AdjustedRentSquareFeet] = useState(adjustedRent);
    const [_adjustedUsageSquareFeet, set_AdjustedUsageSquareFeet] = useState(adjustedUsage);
    const [_ansiRentSquareFeet, set_AnsiRentSquareFeet] = useState(ansiRent);
    const [_ansiUsableSquareFeet, set_AnsiUsableSquareFeet] = useState(ansiUsable);
    const [_jointUseSqareFeet, set_JointUseSquareFeet] = useState(jointUse);
    const [_communityJointUseSquareFeet, set_CommunityJointUseSquareFeet] = useState(communityJointUse);
    const [_leaseJointUseSquareFeet, set_LeaseJointUseSquareFeet] = useState(leaseJointUse);
    const [_rentUsageFactor, set_RentUsageFactor] = useState(rentUsageFactor);
    const [_congressionalRep, set_CongressionalRep] = useState(congressionalRep);
    const [canSubmitForm, set_CanSubmitForm] = useState(false);

    const [buildingSaved, setBuildingSaved] = useState(false);
    const [buildingSaveFail, setBuildingSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [canUpdateBuildingStatus, setCanUpdateBuildingStatus] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    /**
     * @todo: Export this function for global sharing
    */
    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_REGION_AND_BUILDING_DATA.withSlash);
    const { role: { ModulePermissions } } = user;
    const { module: route , title } = ROUTE_EDIT_BUILDING;

    useEffect(() => {
        document.title = title;
        setCanUpdateBuildingStatus(permissionsManager());
    }, []);

    useEffect(() => {
        enableFormSubmission();
    }, [
        _buildingName,
        _facility,
        _address1,
        _cityName,
        _state,
        _zipCode,
        _status,
        _region
    ]);

    const permissionsManager = () => {
        let authorized = false;
        const module = ModulePermissions.filter((module) => {
            return module.Page === route, module.Deactivate
        });
        if (module !== undefined) {
            if (module !== null) {
                if (module.length > 0) {
                    authorized = true;
                }
            }
        }
        return authorized;
    }

    const validateStatus = (value) => {
        return !IsNullOrEmpty(value);
    };

    const validateFields = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        };
        return true;
    }

    const enableFormSubmission = () => {
        const result = (
            validateFields(_buildingName)
            && validFacility(_facility)
            && validRegion(_region)
            && validateFields(_address1)
            && validateFields(_cityName)
            && validateState(_state)
            && validZipCode(_zipCode)
            && validateStatus(_status)
        );

        set_CanSubmitForm(result);
        return result ;
    };

    const validFacility = (value) => {
        if (IsNullOrEmpty(value)) return false;
        if (value.length > 4) return false;
        return true;
    };

    const validRegion = (value) => {
        if (IsNullOrEmpty(value)) return false;
        return true;
    };
    const validateState = (value) =>{
        if (IsNullOrEmpty(value)) return false;
        return true;
    }
    const validZipCode = (value) => {
       if(value && (value.length === 5 || value.length === 9 || value.length === 10))
      { 
        var cnt =  value.match(/-/g) ;
       if(cnt && cnt.length > 1)
        return false ;
      
       if (value && !isNaN(value) &&  (value.length === 5 || value.length === 9) || (cnt && cnt.length === 1 && value.length === 10))
             return true;
       }
        return false;
    }; 
    const setTheZipCode = (value)=>{
        if( value && value.length === 9 && !isNaN(value))
        {
          set_ZipCode(value.slice(0, 5) + '-' + value.slice(5, 9));          
        }
            else
            set_ZipCode(value);
    }
    const sanitize = (value) => {
        if (value === undefined) {
            return null;
        }
        if (value === null) {
            return null;
        }
        return value.trim();
    };

    const handleSubmit = async () => {
        console.log("Clicked Submit Button")
        if (!canSubmitForm || !enableFormSubmission())  {
            console.error('Can\'t be submitted', buildingObject)
            return;
        }
        setSubmitting(true);
        const buildingObject = {
            "SequenceNumber":  sanitize(BuildingNumber),
            "Name":  sanitize(_buildingName),
            "Status": sanitize(_status),
            "Facility": sanitize(_facility),
            "RegionNumber": _region,
            "BuildingId": BuildingId,
            "Location": {
                "Id": locationId,
                "Address1": sanitize(_address1),
                "Address2": sanitize(_address2),
                "CityName": sanitize(_cityName),
                "State": sanitize(_state),
                "ZipCode": sanitize(_zipCode)
            },
            "RentUsageFactor": _rentUsageFactor,
            "AdjustedRentSqFt": _adjustedRentSquareFeet,
            "AdjustedUsageSqFt": _adjustedUsageSquareFeet,
            "AnsiRentSqFt": _ansiRentSquareFeet,
            "AnsiUsableSqFt": _ansiUsableSquareFeet,
            "JointUseSqFt": _jointUseSqareFeet,
            "CommunityJointUseSqFt": _communityJointUseSquareFeet,
            "LeaseJointUseSqFt": _leaseJointUseSquareFeet,
            "CongressionalRep": sanitize(_congressionalRep),
            "Version": version
        };

        const request = await PutPostPatchFetch('/v1/Building/updateBuilding', 'PUT', buildingObject);
        const { Success, Errors } = request;

        if (request === undefined) {
            setBuildingSaved(false);
            setBuildingSaveFail(true);
            setErrorMessage("Something went wrong... Please correct your inputs and try again");
            setSubmitting(false);
            return;
        }

        if (Success) {
            setBuildingSaved(true);
            setBuildingSaveFail(false);
        }
        else {
            setBuildingSaved(false);
            setBuildingSaveFail(true);
            setErrorMessage(Errors[0].Message);
            console.log("What went wrong:", Errors);
        }
        setSubmitting(false);
    }  
    return (
        <div>
            <h1>{title}</h1>
            {
                // (
                //     BuildingId
                //     && locationId
                //     && _buildingName
                //     && _facility
                //     && _region
                //     && _status
                //     && _address1
                //     && _address2
                //     && _zipCode
                //     && _state
                //     // && _adjustedRentSquareFeet
                //     // && _adjustedUsageSquareFeet
                //     // && _ansiRentSquareFeet
                //     // && _ansiUsableSquareFeet
                //     // && _jointUseSqareFeet
                //     // && _communityJointUseSquareFeet
                //     // && _leaseJointUseSquareFeet
                //     // && _rentUsageFactor
                //     // && _congressionalRep
                // )
                //     ?
                //     <LoadingIndicatorPageLength message={"Getting your data ready... Please Wait..."} />
                //     :
                    <>
                        <form className="inline__div--wrapper" method="post">
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Building Sequence Number" }}
                                hoverStateEnabled={false}
                                label={buildingSequenceNumberLabel}
                                labelMode={_floating}
                                readOnly={true}
                                value={BuildingNumber}
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Building Name" }}
                                isValueValid={_buildingName && _buildingName.length > 0}
                                label={buildingNameLabel}
                                labelMode={_floating}
                                value={_buildingName}
                                valueUpdatedByUser={
                                    ({ value }) => set_BuildingName(value)
                                }
                            />
                            <BuildingStatusSelectField
                                inputAttribute={{ "aria-label": "Status" }}
                                enableSearch={true}
                                label={statusLabel}
                                labelMode={_floating}
                                value={_status}
                                readOnly={!canUpdateBuildingStatus}
                                optionUpdatedByUser={
                                    ({selectedItem}) => {
                                        if (canUpdateBuildingStatus) {
                                            set_Status(selectedItem)
                                        }
                                    }
                                }
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Facility" }}
                                enableSearch={true}
                                isValueValid={_facility && _facility.length > 0}
                                label={buildingFacilityLabel}
                                labelMode={_floating}
                                maxLength={4}
                                value={_facility}
                                valueUpdatedByUser={
                                    ({ value }) => set_Facility(value)
                                }
                            />
                            <SingleRegionSelectField
                                inputAttribute={{ "aria-label": "Building Region" }}
                                enableSearch={true}
                                label={singleRegionLabel}
                                labelMode={_floating}
                                optionUpdatedByUser={({selectedItem }) => { set_Region(selectedItem) }}
                                value={_region}
                                isVisible={true}
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Address One" }}
                                isValueValid={_address1 && _address1.length > 0}
                                label={addressOneLabel}
                                labelMode={_floating}
                                value={_address1}
                                valueUpdatedByUser={
                                    ({ value }) => set_Address1(value)
                                }
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Address Two" }}
                                label={addressTwoLabel}
                                labelMode={_floating}
                                isRequired = {false}
                                value={_address2}
                                valueUpdatedByUser={
                                    ({ value }) => set_Address2(value)
                                }
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "City Name" }}
                                isValueValid={_cityName  && _cityName.length > 0}
                                label={cityNameLabel}
                                labelMode={_floating}
                                value={_cityName}
                                valueUpdatedByUser={
                                    ({ value }) => { set_CityName(value) }
                                }
                            />
                            <US_StatesSelectField
                                inputAttribute={{ "aria-label": "State Abbreviation" }}
                                enableSearch={true}
                                isVisible={true}
                                optionUpdatedByUser={({ selectedItem }) => set_State(selectedItem)}
                                value={_state}
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Zip Code" }}
                                isValueValid= {validZipCode(_zipCode)}
                                label={zipCodeLabel}
                                labelMode={_floating}                              
                                value={_zipCode}
                                valueUpdatedByUser={
                                    ({ value }) => setTheZipCode(value)
                                }
                                customValidationMessage = "invalid zip code format. Zip code can only be 5 or 9 digit."
                            />
                            <BaseTextField
                                inputAttribute={{ "aria-label": "Congressional Representative" }}
                                label={congressionalRepresentativeLabel}
                                labelMode={_floating}
                                isRequired = {false}
                                value={_congressionalRep}
                                valueUpdatedByUser={
                                    ({ value }) => set_CongressionalRep(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Rent Usage Factor" }}
                                label={rentUsageFactorLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_rentUsageFactor}
                                valueUpdatedByUser={
                                    ({ value }) => set_RentUsageFactor(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Adjusted Rent Square Footage" }}
                                label={adjustedRentSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_adjustedRentSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_AdjustedRentSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Adjusted Usage Square Footage" }}
                                label={adjustedUsageSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_adjustedUsageSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_AdjustedUsageSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Ansi Rent Square Footage" }}
                                label={ansiRentSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_ansiRentSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_AnsiRentSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Adjusted Usable Square Footage" }}
                                label={ansiUsableSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_ansiUsableSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_AnsiUsableSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Joint Usage Square Footage" }}
                                label={jointUseSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_jointUseSqareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_JointUseSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Community Joint Use Square Footage" }}
                                label={communityJointUsageSquareFootage}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_communityJointUseSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_CommunityJointUseSquareFeet(value)
                                }
                            />
                            <BaseNumberBox
                                inputAttribute={{ "aria-label": "Lease Joint Use Square Footage" }}
                                label={leaseJointUseSquareFootageLabel}
                                labelMode={_floating}
                                numberFormat={decimalFormat}
                                value={_leaseJointUseSquareFeet}
                                valueUpdatedByUser={
                                    ({ value }) => set_LeaseJointUseSquareFeet(value)
                                }
                            />
                            <BaseTextField
                                disabled={true}
                                inputAttribute={{ "aria-label": "building_id--hidden" }}
                                isVisible={false}
                                value={BuildingId}
                            />
                        </form>
                        <div className="block--flex-items">
                            {!submitting ? <>
                                <BaseButton
                                    label={cancel}
                                    variant={outlined}
                                    onClick={navigateToRoute}
                                />
                                <BaseButton
                                    ariaDisabled={!canSubmitForm ? true : false}
                                    disabled={!canSubmitForm}
                                    label={submit}
                                    onClick={handleSubmit}
                                    variant={outlined}
                                />
                            </>
                                :
                                <LoadingIndicatorPageLength message="Submitting... Please wait..." />
                            }
                        </div>
                    </>
            }
            <ClosingAlert
                clickableIcon={navigateToRoute}
                enableAlertIcon={true}
                message={BuildingSavedSuccssful}
                severity={success}
                visible={buildingSaved}
            />
            <ClosingAlert
                hideAlert={() => { setBuildingSaveFail(false) }}
                message={errorMessage}
                severity={warning}
                visible={buildingSaveFail}
            />
        </div >
    );
}

export default BuildingsUpdate;