import { BaseTextField } from '../../../components/BaseTextField/BaseTextField';
import { BaseNumberBox } from '../../../components/BaseNumberBox/BaseNumberBox';
import './ItemFormComponent.scss';

export const ItemFormComponent = ({ data }) => {
    const { 
        AwardDate,
        EndDate,
        StartDate,
        TaskOrderCount,
        TaskOrderId,
        TotalCosts
    } = data;

    return (
        <>
            {
                TaskOrderId &&
                <>
                    <BaseNumberBox
                        numberFormat={{
                            type: 'fixedPoint'
                        }} 
                        label={"Task Order ID"}
                        readOnly={true}
                        value={TaskOrderId ?? "N/A"}
                    />   
                    <BaseTextField 
                        label={"Award Date"}
                        readOnly={true}
                        value={AwardDate ?? "N/A"}
                    />   
                    <BaseTextField 
                        label={"Start Date"}
                        readOnly={true}
                        value={StartDate ?? "N/A"}
                    />  
                    <BaseTextField 
                        label={"End Date"}
                        readOnly={true}
                        value={EndDate ?? "N/A"}
                    />
                </>
            }
            {
                ( TotalCosts && TaskOrderCount ) &&
                <>
                    <BaseNumberBox
                        numberFormat={{
                            type: 'fixedPoint'
                        }} 
                        label={"Total Task Orders"}
                        readOnly={true}
                        value={TaskOrderCount ?? "N/A"}
                    />
                    <BaseNumberBox
                        numberFormat={{
                            type: 'currency',
                            precision: 0
                        }}
                        label={"Total PSO Contract Estimated Cost"}
                        readOnly={true}
                        value={TotalCosts ?? "N/A"}
                    />
                </>
            }
        </>
    );
};