
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridtoExcel} from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { GetDateToday_dashes } from '../util/GetDateToday';
import { jsPDF } from 'jspdf';

/**
 * Use this function to pass a unique name of a file for each 
 * component: e.g. onExporting={e => ExportGrid(e, "Enter_xlsx_name_here")} 
 * 
 * @param {*} component 
 * @param {string} format 
 * @param {string} incomingFileName 
 */

const ExportGrid = (
    { 
        component, 
        format, 
    },
    incomingFileName,
    wrapPdf,
    fontSize
) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet( 'Main sheet' );
    const exportOptions = {       
        format: 'A4', // Specify the page size (e.g., 'A4')
        fitToPageWidth: true, // Ensure the content fits the page width
        fitToPageHeight: true, // Ensure the content fits the page height
      
    };
    let customOptions,
        exportFileNameContructor; 

    switch ( format ) {
        case "xlsx":
            exportFileNameContructor = `${incomingFileName}_${GetDateToday_dashes()}.xlsx`;
            customOptions = {
                component: component,
                worksheet: worksheet,
                customizeCell: (
                    { excelCell: alignment, font }
                ) => {
                    alignment = { horizontal: 'left' };
                    font = { name: 'Arial', size: 12 };
                }
            };
            
            exportDataGridtoExcel( customOptions )
            .then(() => {
                workbook.xlsx.writeBuffer()
                .then( buffer => {
                    saveAs(
                        new Blob(
                            [ buffer ], 
                            { type: 'application/octet-stream' }
                        ), 
                        exportFileNameContructor
                    );
                });
            });
            break;
    
        case "pdf":
            const doc = new jsPDF({ orientation: 'landscape' });
            exportFileNameContructor = `${incomingFileName}_${GetDateToday_dashes()}.pdf`;
            const saveToPDF = () => doc.save(exportFileNameContructor);          
            
            exportDataGridToPdf({
                jsPDFDocument: doc ,
                component: component,
                customizeCell({ pdfCell }){ 
                    wrapPdf == true ? pdfCell.font.size = fontSize : true,
                    wrapPdf == true ? pdfCell.wordWrapEnabled = true : pdfCell.wordWrapEnabled = false 
                },
                ...exportOptions,
            })
            .then(saveToPDF);
         break;

        case "csv":
            exportDataGrid({
                component: component,
                worksheet: worksheet
            })
            .then(() => {
                workbook.csv.writeBuffer()
                .then(buffer => {
                    saveAs(
                        new Blob(
                            [ buffer ],
                            { type: "application/octet-stream" }
                        ),
                        `${incomingFileName}_${GetDateToday_dashes()}.csv`
                    );
                });
            });
    };

};

export default ExportGrid;