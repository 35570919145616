import * as React from 'react';
import Button from '@mui/material/Button';
import {
    ButtonGroup
    , FormControl
    , FormGroup
    , Grid
    , TextField
    , Typography
    , Autocomplete
    , Select
    , OutlinedInput
    , MenuItem
    , ListItemText
    , FormHelperText
} from '@mui/material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import { useState, useEffect } from 'react';
import GetFetch from '../../hooks/GetFetch';
import PutPostPatchFetch from '../../hooks/PutPostPatchFetch';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from '../../hooks/useAuth';
import ClosingAlert from '../../components/ClosingAlert';
import  SearchBtnGroupSelect  from './SearchBtnGroupSelect';
const dateFieldSx = {
    width: 140
    , mr: 1
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const SearchButtonGroupUser = ({ setter }) => {
    const { user } = useAuth();
    const [allRoles, setAllRoles] = useState([]);
    const [allStatuses, setAllStatuses] = useState([]);
    const allRegions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
    const [allUserIds, setAllUserIds] = useState([]);

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [usernames, setUsernames] = useState(null);
    const [loginDate, setLoginDate] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);
    const [deactivateDate, setDeactivateDate] = useState(null);
    const [reactivateDate, setReactivateDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [terminationDate, setTerminationDate] = useState(null);
    const [suspensionDate, setSuspensionDate] = useState(null);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        RetrieveRoles();
        RetrieveStatuses();
        RetrieveUserIds();
    }, [])

    const RetrieveRoles = async () => {
        const res = await GetFetch('/v1/SystemRoles/GetRoles');
        if (res.Success === true) {
            setAllRoles(res.Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(res.Errors[0].Message);
            setAllRoles(null);
            console.error("What went wrong:", res.Errors);
        }
    };

    const RetrieveStatuses = async () => {
        const { Message, Success, Errors } = await GetFetch('/v1/SystemUser/GetUserStatuses');
        if (Success === true) {
            setAllStatuses(Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
            setAllStatuses(null);
            console.error("What went wrong:", Errors);
        }
    };

    const RetrieveUserIds = async () => {
        const { Message, Success, Errors } = await GetFetch('/v1/SystemUser/GetUsernames');
        if (Success === true) {
            setAllUserIds(Message);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(Errors[0].Message);
            setAllUserIds(null);
            console.error("What went wrong:", Errors);
        }
    };

    const handleClear = () => {
        setSelectedFilters([]);
    };

    useEffect(() => {
            if (!selectedFilters?.includes('Start Date')) setStartDate(null);
            if (!selectedFilters?.includes('End Date')) setEndDate(null);
            if (!selectedFilters?.includes('Usernames')) setUsernames(null);
            if (!selectedFilters?.includes('Last Login Date')) setLoginDate(null);
            if (!selectedFilters?.includes('Roles')) setSelectedRoles(null);
            if (!selectedFilters?.includes('Regions')) setSelectedRegions(null);
            if (!selectedFilters?.includes('User Statuses')) setSelectedStatuses(null);
            if (!selectedFilters?.includes('Job Title')) setJobTitle(null);
            if (!selectedFilters?.includes('Deactivation Date')) setDeactivateDate(null);
            if (!selectedFilters?.includes('Reactivation Date')) setReactivateDate(null);
            if (!selectedFilters?.includes('Creation Date')) setCreatedDate(null);
            if (!selectedFilters?.includes('Termination Date')) setTerminationDate(null);
            if (!selectedFilters?.includes('Suspension Date')) setSuspensionDate(null);
    },[selectedFilters]);


    const handleSearch = async () => {

        const userReportObject = {
            "StartDate": startDate,
            "EndDate": endDate,
            "UserIds": usernames,
            "Roles": selectedRoles,
            "Regions": selectedRegions,
            "JobTitle": jobTitle,
            "LoginDate": loginDate,
            "Statuses": selectedStatuses,
            "DeactivationDate": deactivateDate,
            "ReactivationDate": reactivateDate,
            "TerminationDate": terminationDate,
            "SuspensionDate": suspensionDate,
            "CreatedDate": createdDate
        };

        setter(null);
        const result = await PutPostPatchFetch(
            '/v1/SystemUser/UserReport', 'PUT', userReportObject
        );
        if (result.Success === true) {
            setter(result);
            setError(false);
            setErrorMessage(null);
        }
        else {
            setError(true);
            setErrorMessage(result.Errors[0].Message);
            setter(null);
            console.log("What went wrong:", result.Errors);
        }
    }


    return (
        <FormGroup sx={{ width: '97%', margin: 'auto' }}>
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Search by selected filters. Leave field(s) empty to receive all results.
            </Typography>
            <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ my: 1 }}
            >
                <Grid container>
                <Grid item>
                <SearchBtnGroupSelect value={selectedFilters} setter={setSelectedFilters} />
                </Grid>
                {selectedFilters && <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {selectedFilters.includes('Start Date') &&
                            <Grid item><DateField
                                sx={dateFieldSx}
                                id="start-date"
                                label="Start Date"
                                format="MM-DD-YYYY"
                                value={startDate}
                                onChange={(newValue) => { setStartDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('End Date') &&
                                <Grid item><DateField
                                id="end-date"
                                sx={dateFieldSx}
                                label="End Date"
                                format="MM-DD-YYYY"
                                value={endDate}
                                onChange={(newValue) => { setEndDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('Creation Date') &&
                                <Grid item><DateField
                                id="created-date"
                                sx={dateFieldSx}
                                label="User Created Date"
                                format="MM-DD-YYYY"
                                value={createdDate}
                                onChange={(newValue) => { setCreatedDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('Last Login Date') &&
                                <Grid item><DateField
                                id="last-login-date"
                                sx={dateFieldSx}
                                label="Last Login Date"
                                format="MM-DD-YYYY"
                                value={loginDate}
                                onChange={(newValue) => { setLoginDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('Termination Date') &&
                                <Grid item><DateField
                                id="termination-date"
                                sx={dateFieldSx}
                                label="Termination Date"
                                format="MM-DD-YYYY"
                                value={terminationDate}
                                onChange={(newValue) => { setTerminationDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('Suspension Date') &&
                                <Grid item><DateField
                                id="suspension-date"
                                sx={dateFieldSx}
                                label="Suspension Date"
                                format="MM-DD-YYYY"
                                value={suspensionDate}
                                onChange={(newValue) => { setSuspensionDate(newValue) }}
                                /></Grid>
                        }
                        {selectedFilters.includes('Deactivation Date') &&
                                <Grid item><DateField
                                id="deactivation-date"
                                sx={dateFieldSx}
                                label="Deactivation Date"
                                format="MM-DD-YYYY"
                                value={deactivateDate}
                                onChange={(newValue) => { setDeactivateDate(newValue) }}
                            /></Grid>
                        }
                        {selectedFilters.includes('Reactivation Date') &&
                                <Grid item><DateField
                                id="reactivation-date"
                                sx={dateFieldSx}
                                label="Reactivation Date"
                                format="MM-DD-YYYY"
                                value={reactivateDate}
                                onChange={(newValue) => { setReactivateDate(newValue) }}
                                /></Grid>
                        }
                    </LocalizationProvider>
                    {selectedFilters.includes('Roles') &&
                        <Grid item>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="roles-select"
                                sx={{ width: 150 }}
                                limitTags={2}
                                options={allRoles}
                                getOptionLabel={(option) => option.Name}
                                defaultValue={[]}
                                value={selectedRoles || []}
                                onChange={(event, newValue) => {
                                    setSelectedRoles(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="Roles" />}
                            />
                        </Grid>
                    }
                    {selectedFilters.includes('User Statuses') &&
                        <Grid item>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="status-select"
                                sx={{ width: 150 }}
                                limitTags={2}
                                options={allStatuses}
                                getOptionLabel={(option) => option.Name}
                                defaultValue={[]}
                                value={selectedStatuses || []}
                                onChange={(event, newValue) => {
                                    setSelectedStatuses(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="Statuses" />}
                            />
                        </Grid>
                    }
                    {selectedFilters.includes('Regions') &&
                        <Grid item>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="regions-select"
                                sx={{ width: 150 }}
                                limitTags={2}
                                options={allRegions}
                                defaultValue={[]}
                                value={selectedRegions || []}
                                onChange={(event, newValue) => {
                                    setSelectedRegions(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="Regions" />}
                            />
                        </Grid>
                    }
                    {selectedFilters.includes('Usernames') &&
                        <Grid item>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="username-select"
                                sx={{ width: 150 }}
                                limitTags={2}
                                options={allUserIds}
                                defaultValue={[]}
                                value={usernames || []}
                                onChange={(event, newValue) => {
                                    setUsernames(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="User IDs" />}
                            />
                        </Grid>
                    }
                    {selectedFilters.includes('Job Title') &&
                        <Grid item><FormControl variant="filled" >
                            <div style={{ marginRight: '10px' }}>
                                <TextField
                                    id="Job Title"
                                    label="Changed By"
                                    value={jobTitle || ""}
                                    onChange={e => setJobTitle(e.target.value)} />
                            </div>
                        </FormControl></Grid>
                    }
                    </>
                    }
                <br/>
                <Grid item variant="subtitle1" component="div"  sx={{marginTop:3}}>
                <Button
                    id="submit-button"
                    variant="contained"
                    onClick={handleSearch}
                    startIcon={<QueryStatsRoundedIcon />}
                >
                    Generate Report
                        </Button>

                <Button
                    id="clear-button"
                    color="secondary"
                    variant="contained"
                    onClick={handleClear}
                    sx={{
                        mx: 2
                    }}
                    startIcon={<CleaningServicesRoundedIcon />}
                >
                    Clear filter options
                </Button>
                </Grid>
                </Grid>
            </ButtonGroup>

            <ClosingAlert
                severity="error"
                message={errorMessage}
                visible={error}
                hideAlert={() => { setError(false) }}
            />
            {/* <CircularProgress color="secondary" /> */}
        </FormGroup>
    );
}

export default SearchButtonGroupUser;