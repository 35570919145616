import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { BaseButton } from "../components/BaseButton/BaseButton";
import { BaseNumberBox } from "../components/BaseNumberBox/BaseNumberBox";
import { Content } from "../Enums/contents"
import { ROUTE_ADD_ADJUSTMENTS } from "../routes/Routes";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import ClosingAlert from "../components/ClosingAlert";
import "./css/Adjustments.scss"
import BuildingSingleSelectDevEx from "../Reports/CostEstimateReport/BldgNumberDropdownDevEx";
import { ROUTE_BILLING_ADJUSTMENTS } from "../routes/Routes";
import OASingleSelectDevEx from "./components/OASingleSelectDevEx";
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReasonSingleSelectDevEx from "./components/ReasonSingleSelectDevEx";
import { TextBox } from "devextreme-react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import FeeTypeSingleSelectDevEx from "./components/FeeTypeSingleSelectDevEx";
import { LoadingIndicatorCircle } from "../components/Loading";

const dateFieldSx = {
    mt: 2.2,
    mr: 1,
    width: 200
};

const {
    Alerts: {
        AdjustmentSavedSuccssful
    },
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        labelModes: {
            _static
        },
        Input_TextField_Labels: {
        }
    },
    NummberFormatting: {
        currencyFormat
    }
} = Content;

const AddAdjustment = () => {
    const title = ROUTE_ADD_ADJUSTMENTS.title;
    const navigate = useNavigate();
    const [buildingSeqNo, setBuildingSeqNo] = useState('');
    const [oaNo, setOaNo] = useState();
    const [feeType, setFeeType] = useState();
    const [reason, setReason] = useState('');
    const [psoAdjustment, setPSOAdjustment] = useState(0);
    const [tcmAdjustment, setTCMAdjustment] = useState(0);
    const [adjustedAmount, setAdjustedAmount] = useState();
    const [adjustedAmountDisplay, setAdjustedAmountDisplay] = useState("No Adusted Amount available with current entries");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [adjustmentSaved, setAdjustmentSaved] = useState(false);
    const [error, setError] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [adjustedError, setAdjustedError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [existing, setExisting] = useState(false);
    const [existingOAs, setExistingOAs] = useState([])

    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        if (validateAdjustments() && adjustedAmount != 0 &&
            oaNo && oaNo.BuildingAnsiRent != 0
            && feeType
            && reason
            && startDate >= minDate && startDate < endDate
            && endDate > startDate && endDate <= maxDate) setCanSubmitForm(true);
        else setCanSubmitForm(false)
    }, [psoAdjustment,
        tcmAdjustment,
        adjustedAmount,
        oaNo,
        feeType,
        reason,
        startDate,
        endDate]);

    useEffect(() => {
        if (oaNo?.BuildingAnsiRent > 0) {
            setAdjustedAmount((psoAdjustment + tcmAdjustment) * oaNo.TotalRentSqFt / oaNo.BuildingAnsiRent)
        }
        else setAdjustedAmount()

    },
        [psoAdjustment,
            tcmAdjustment,
            oaNo]);

    useEffect(() => {
        if (adjustedAmount != null) {
            setAdjustedAmountDisplay(adjustedAmount);
            setAdjustedError(false)
        }
        else if (!oaNo?.BuildingAnsiRent || oaNo?.BuildingAnsiRent <= 0) {
            setAdjustedAmountDisplay("Unable to calculate. Please check the Building's ANSI rent value is greater than 0")
            setAdjustedError(true);
        }
        else {
            setAdjustedAmountDisplay("No Adusted Amount available with current entries. Please ensure either PSO or TCM adjustments total a value other than 0");
            setAdjustedError(false);
        }
    },
        [adjustedAmount]);



    const returnToAdjustmentsPage = () => {
        navigate(ROUTE_BILLING_ADJUSTMENTS.withSlash);
    };

    const validateAdjustments = () => {
        if (psoAdjustment == 0 && tcmAdjustment == 0)
            return false;
        return true;
    }

    const minDate = new Date((new Date().getFullYear() - 5), (new Date().getMonth()), 1);
    const minDateFormatted = `${minDate.getMonth() + 1}-${minDate.getFullYear()}`;
    const maxDate = new Date((new Date().getFullYear()), (new Date().getMonth()), 0);
    const maxDateFormatted = `${maxDate.getMonth() + 1}-${maxDate.getFullYear()}`;
    const dateMessage = `Date must be between ${minDateFormatted} and ${maxDateFormatted}`;

    const handleSubmit = async () => {
        if (!canSubmitForm) {
            console.error('Can\'t be submitted')
            return;
        };
        setSubmitting(true);
        var searchCriteria = {
            "BuildingNumbers": [buildingSeqNo]
        };
        const existing = await PutPostPatchFetch('/v1/Adjustments/ByBuildingNumberList', 'POST', searchCriteria)
        if (existing?.Success) {
            if (existing.Message?.length > 0) {
                let oldest = new Date(new Date().getFullYear() - 6, new Date().getMonth(), new Date().getDay())
                var existingAdjs = existing.Message.filter(i => (new Date(i.BillPeriodEnd) > oldest && i.OccupancyAgreementId == oaNo.OccupancyAgreementId))
                if (existingAdjs.length > 0) {
                    setExistingOAs(existingAdjs);
                    setExisting(true);
                    return;
                }
            }
        }
        setExisting(false);
        handleCreate();

    }

    const generateExisting = () => {
        return (
            existingOAs.map((existingValue) => { return (<p>An adjustment has already been applied to the Occupancy Agreement number {existingValue.OccupancyAgreement.OANumber} of ${existingValue.AdjustedAmount} for period {existingValue.BillPeriodStart} - {existingValue.BillPeriodEnd}</p>) })
        )
    }

    const handleCreate = async () => {
        if (!canSubmitForm) {
            console.error('Can\'t be submitted')
            return;
        };
        var adjustedStart = startDate.date(1);
        var adjustedEnd = endDate.date(1);

        console.log(feeType)
        var adjustment = {
            AdjustedAmount: adjustedAmount,
            PSOAmount: psoAdjustment,
            TCMAmount: tcmAdjustment,
            OccupancyAgreementId: oaNo.OccupancyAgreementId,
            FeeTypeId: feeType.FeeTypeId,
            Reason: reason,
            BillPeriodStart: adjustedStart.format('YYYY-MM-DD'),
            BillPeriodEnd: adjustedEnd.format('YYYY-MM-DD')
        }
        const { Success, Errors } = await PutPostPatchFetch('/v1/Adjustments/AddAdjustment', 'POST', adjustment);

        if (Success) {
            setAdjustmentSaved(true);
            setError(false);
            setSubmitting(false);
        }
        else {
            setAdjustmentSaved(false);
            setError(true);
            setErrorMessage(Errors[0].Message ?? "Something went wrong... Please correct your inputs and try again.");
            console.error(Errors);
            setSubmitting(false);
        };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCreate();
        };
    };

    const handleContinue = () => {
        console.log("creating")
        setExisting(false);
        handleCreate()
    }
    const handleCancel = () => {
        setExisting(false);
        setSubmitting(false)
    }

    return (
        <div>
            <h1 style={{ color: "#06152B" }}>{title}</h1>
            <form className="inline__div--wrapper" method="post">

                <BuildingSingleSelectDevEx
                    value={buildingSeqNo}
                    setter={setBuildingSeqNo}
                    isRequired={true}

                />
                <OASingleSelectDevEx
                    value={oaNo}
                    setter={setOaNo}
                    selectedBuilding={buildingSeqNo}
                    isRequired={true}
                />
                <FeeTypeSingleSelectDevEx
                    value={feeType}
                    setter={setFeeType}
                    isRequired={true}
                />
            </form>
            <form className="inline__div--wrapper" method="post">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                        sx={dateFieldSx}
                        id="start-date"
                        label="Bill Period Start Date"
                        format="MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue); setDateErrorMessage('') }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        required
                        onError={(error) => { setDateErrorMessage(dateMessage) }}
                        slotProps={{
                            textField: {
                                helperText: dateErrorMessage
                            }
                        }}
                    />
                    <DateField
                        id="end-date"
                        sx={dateFieldSx}
                        label="Bill Period End Date"
                        format="MM-YYYY"
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue); setDateErrorMessage('') }}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        onError={(error) => { setDateErrorMessage(dateMessage) }}
                        slotProps={{
                            textField: {
                                helperText: dateErrorMessage
                            }
                        }}
                        required
                    />
                </LocalizationProvider>
            </form>
            <form className="inline__div--wrapper" method="post">
                <BaseNumberBox
                    inputAttribute={{ "aria-label": "PSO Amount" }}
                    label="PSO Amount *"
                    labelMode={_static}
                    numberFormat={currencyFormat}
                    onEnterKeyDown={handleKeyDown}
                    value={psoAdjustment}
                    valueUpdatedByUser={
                        ({ value }) => setPSOAdjustment(value)
                    }
                    validationRuleCallback={() => validateAdjustments()}
                    isValueValid={validateAdjustments()}
                    validationRequired={true}
                    errMsg="At least one value must be non-zero."
                />
                <BaseNumberBox
                    inputAttribute={{ "aria-label": "TCM Amount" }}
                    label="TCM Amount *"
                    labelMode={_static}
                    numberFormat={currencyFormat}
                    onEnterKeyDown={handleKeyDown}
                    value={tcmAdjustment}
                    valueUpdatedByUser={
                        ({ value }) => setTCMAdjustment(value)
                    }
                    validationRuleCallback={() => validateAdjustments()}
                    isValueValid={validateAdjustments()}
                    validationRequired={true}
                    errMsg="At least one value must be non-zero."
                />
                <ReasonSingleSelectDevEx
                    value={reason}
                    setter={setReason}
                />
            </form>
            {//adjustedAmount &&
                <form className="inline__div--wrapper" >
                    <TextBox
                        label="Calculated Adjusted Amount"
                        labelMode='floating'
                        value={adjustedAmountDisplay}
                        height={56}
                        maxLength={150}
                        width={700}
                        ignoreEmptyValue={true}
                        id="adjusted_amount_textBox_002"
                        isValid={!adjustedError}
                        readOnly
                    >
                    </TextBox>
                </form>
            }
            {(submitting) ? (
                <LoadingIndicatorCircle message="submitting, please wait ..." />
            ) :
                <form className="inline__div--wrapper" >
                    <BaseButton
                        ariaDisabled={!canSubmitForm}
                        disabled={!canSubmitForm}
                        label={submit}
                        onClick={handleSubmit}
                        variant={outlined}
                    />
                    <BaseButton
                        label={cancel}
                        variant={outlined}
                        onClick={returnToAdjustmentsPage}
                    />
                </form>
            }
            <Popup
                visible={existing}
                onHiding={() => { setExisting(false) }}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButon={false}
                showTitle={true}
                title="WARNING: Existing Adjustment(s)"
                contianer=".dx-viewport"
                width={500}
                height={500}
            >
                <Position at="center" my="center" />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={{
                        stylingMode: 'outlined',
                        text: 'Proceed',
                        onClick: handleContinue
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        stylingMode: 'outlined',
                        text: 'Cancel',
                        onClick: handleCancel
                    }}
                />
                {generateExisting()}
            </Popup>
            <ClosingAlert
                clickableIcon={returnToAdjustmentsPage}
                enableAlertIcon={true}
                message={AdjustmentSavedSuccssful}
                severity={success}
                visible={adjustmentSaved}
            />
            <ClosingAlert
                hideAlert={() => { setError(false) }}
                message={errorMessage}
                severity={warning}
                visible={error}
            />
        </div >
    );
};

export default AddAdjustment;