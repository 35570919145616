import { NumberBox } from 'devextreme-react';
import { Dimensions } from '../../Enums/dimensions';
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import './BaseNumberBox.scss';


const { Heights: { inputHeight } } = Dimensions;

/**
 * @module BaseNumberBox
 * This is the base of Number Box for RMS.
 * It is a text input that has been customized by DevExtreme to only accept integers and floats as values. All other value types will be ignored.
 */

export const BaseNumberBox = (
    /**
     * @param { Object | Format | String | Function } format: Specifies the value's display format and restricts the user's input to that format accordingly. Optional. Defaults to "" (empty string).
     * @param { String | Number | Function } height: Specifies the UI components height. Optional. Defaults to undefined.
     * @param { Object } inputAttr: Sets any global HTML attributes to the component. Optional. Defualts to {}.
     * @param { Boolean } isValid: Specifies or indicates if a field's value is valid (for required fields). Required only if the field is a required field.. Defaults to true.
     * @param { String } label: The visible label (text) for the button. An asterisk (*) is manditory each label by placing one space after the end. Required. Defaults to "" (empty string).
     * @param { String } labelMode: Specifies the visible experience (mode) for how a label should persist when a user is filling the text field with a value. Accepts "static" (label is displayed above the input field), "floating" (label is used as a placeholder, but is moved to above the field once the field has focus) or "hidden" (the label is hidden). If autofill is enabled by the browser, avoid using "floating mode." Optional. Defaults to "static".
     * @param { Function } onValueChanged: Accepts a function that updates the state of the text field with a new value. Required, if not read-only.
     * @param { Number } value: Sets the initial state of the number box. Required in order to set default value to a field. Defaults to 0 (zero).
     * @param { String } name: The value to be assigned to the name attribute of the underlying HTML element. 
     */
    {
        errMsg,
        inputAttribute,
        isValueValid,
        label,
        labelMode,
        numberFormat,
        readOnly,
        value,
        valueUpdatedByUser,
        validationRuleCallback,
        validationRequired = false,
        name
    }
) => {
    if (name === undefined || name === null) {
        if (label !== null || label !== undefined) {
            name = label.replace(/\s/g, '');
        }
    }
    return (
        <span className="inline__span--numberbox-wrapper">
            <NumberBox
                format={numberFormat}
                height={inputHeight}
                inputAttr={inputAttribute}
                isValid={isValueValid ?? true}
                label={label}
                labelMode={labelMode}
                onValueChanged={valueUpdatedByUser}
                readOnly={readOnly}
                value={value}
                name={name}
            >
                {validationRequired == true &&
                    <Validator >
                        {<RequiredRule type="required" />}
                        <CustomRule
                            message={errMsg ? errMsg : "This field cannot be empty. Please provide a value."}
                            reevaluate={true}
                            validationCallback={validationRuleCallback}
                        />
                    </Validator>
                }
            </NumberBox>
        </span>
    );
};