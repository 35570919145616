import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    FilterRow,
    MasterDetail
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import InfoIconComponent, { InfoIconDevExtremeComponent } from '../../components/CustomInfoIcon';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';
import { RowExpander } from '../../components/RowExpander/RowExpander';

const BuildingChanges = ({ data }) => {
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {
            const mappedData = data.map((element) => {
                let addr = element.ChangedValue.Address;
                let initaddr = element.InitialValue?.Address;

                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    DateChanged: (element.DateChanged === null || element.DateChanged === "")
                    ? "N/A" : dayjs(element.DateChanged).utc().format("MM/DD/YYYY h:mma"),
                    UpdatedDate: element.ChangedValue.UpdatedDate ?? "N/A",
                    UpdatedBy: element.user ?? "N/A",
                    Region: element.ChangedValue.RegionNumber ?? "N/A",
                    BuildingNumber: element.ChangedValue.BuildingSequenceNumber ?? "N/A",
                    BuildingName: element.ChangedValue.BuildingName ?? "N/A",
                    BuildingFacility: element.ChangedValue.BuildingFacility ?? "N/A",
                    BuildingStatus: element.ChangedValue.BuildingStatus ?? "N/A",
                    Address: addr ? (addr.Address1 + " " + addr.Address2 + ", " + addr.CityName + " " + addr.State + " " + addr.ZipCode) : "N/A",
                    AnsiRent: element.ChangedValue.AnsiRentSquareFoot ?? "N/A",
                    AnsiUsable: element.ChangedValue.AnsiUsableSquareFoot ?? "N/A",
                    AdjustedRent: element.ChangedValue.AdjustedRentSquareFoot ?? "N/A",
                    AdjustedUsable: element.ChangedValue.AdjustedUsageSquareFoot ?? "N/A",
                    BuildingJointUse: element.ChangedValue.BuildingJointUseSquareFoot ?? "N/A",
                    CommunityJointUse: element.ChangedValue.CommunityJointUseSquareFoot ?? "N/A",
                    RentUsage: element.ChangedValue.RentUsageFactor ?? "N/A",
                    CongressionalRep: element.ChangedValue.CongressionalRep,
                    InitRegion: element.InitialValue?.RegionNumber ?? "N/A",
                    InitBuildingNumber: element.InitialValue?.BuildingSequenceNumber ?? "N/A",
                    InitBuildingName: element.InitialValue?.BuildingName ?? "N/A",
                    InitBuildingFacility: element.InitialValue?.BuildingFacility ?? "N/A",
                    InitBuildingStatus: element.InitialValue?.BuildingStatus ?? "N/A",
                    InitAddress: initaddr ? (initaddr.Address1 + " " + initaddr.Address2 + ", " + initaddr.CityName + " " + initaddr.State + " " + initaddr.ZipCode) : "N/A",
                    InitAnsiRent: element.InitialValue?.AnsiRentSquareFoot ?? "N/A",
                    InitAnsiUsable: element.InitialValue?.AnsiUsableSquareFoot ?? "N/A",
                    InitAdjustedRent: element.InitialValue?.AdjustedRentSquareFoot ?? "N/A",
                    InitAdjustedUsable: element.InitialValue?.AdjustedUsageSquareFoot ?? "N/A",
                    InitBuildingJointUse: element.InitialValue?.BuildingJointUseSquareFoot ?? "N/A",
                    InitCommunityJointUse: element.InitialValue?.CommunityJointUseSquareFoot ?? "N/A",
                    InitRentUsage: element.InitialValue?.RentUsageFactor ?? "N/A",
                    InitCongressionalRep: element.InitialValue?.CongressionalRep,
                    PreviousEntry: element.InitialValue,
                    CurrentEntry: element.ChangedValue
                }

            });
            return mappedData;
        }
    });
    const pdfFont = ()=>
    {    return 7;   }
    const wrapPdfCell = ()=>{
     return true;   }
    return (
        <div>
            <FOUOheader
            dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}                
            heading={"Audit - Building Changes"}
            userEmail={user.email}
            />
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                onExporting={e => ExportGrid(e, "Audit_Report", wrapPdfCell(), pdfFont() )}
                
            >
                <Column
                    type='detailExpand'
                    cellRender={(data) => RowExpander(data,"BuildingNumber")}
                />
                <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                <Column dataField="DateChanged" caption="Date Changed (UTC)" />
                <Column dataField="ChangeType" caption="Change Type" />
                <Column dataField="UpdatedBy" caption="Changed By" />
                <Column dataField="Region" caption="Region" />
                <Column dataField="BuildingNumber" caption="Building Number Changed" />
                <Column dataField="BuildingName" caption="Building Name" />
                <Column dataField="BuildingFacility" caption="Building Facility" />
                <Column dataField="BuildingStatus" caption="Building Status" />
                <Column dataField="Address" caption="Address" />
                <Column dataField="AnsiRent" caption="ANSI Rent" format="fixedPoint"/>
                <Column dataField="AnsiUsable" caption="ANSI Usable" />
                <Column dataField="AdjustedRent" caption="Adjusted Rent" />
                <Column dataField="AdjustedUsage" caption="Adjusted Usage" />
                <Column dataField="BuildingJointUse" caption="Building Joint Use" />
                <Column dataField="CommunityJointUse" caption="Community Joint Use" />
                <Column dataField="RentUsage" caption="Rent Usage Factor" />
                <Column dataField="CongressionalRep" caption="Congressional Rep" />

                {/* <FilterRow visible={true} /> */}
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
                <MasterDetail
                    enabled={true}
                    render={({ data }) => RenderTemplate(data)}
                />
            </DataGrid>
                <FOUOfooter />
        </div>
    )
}

const renderGridCell = (cellInfo) => {

    if (cellInfo.rowType === 'data') {
        /* Region Number */
        if (cellInfo.column.dataField === 'InitRegion') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.RegionNumber) !== JSON.stringify(cellInfo.data.CurrentEntry.RegionNumber)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Email */
        if (cellInfo.column.dataField === 'InitBuildingNumber') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.BuildingSequenceNumber) !== JSON.stringify(cellInfo.data.CurrentEntry.BuildingSequenceNumber)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* ContractorCompany */
        if (cellInfo.column.dataField === 'InitBuildingName') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.BuildingName) !== JSON.stringify(cellInfo.data.CurrentEntry.BuildingName)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Job Title */
        if (cellInfo.column.dataField === 'InitBuildingFacility') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.BuildingFacility) !== JSON.stringify(cellInfo.data.CurrentEntry.BuildingFacility)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Employee Type */
        if (cellInfo.column.dataField === 'InitBuildingStatus') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.BuildingStatus) !== JSON.stringify(cellInfo.data.CurrentEntry.BuildingStatus)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Contract Start Date */
        if (cellInfo.column.dataField === 'InitAddress') {
            if (cellInfo.data.PreviousEntry.Address?.UpdatedDate !== cellInfo.data.CurrentEntry.Address?.UpdatedDate) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Contract End Date */
        if (cellInfo.column.dataField === 'InitAnsiRent') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.AnsiRentSquareFoot) !== JSON.stringify(cellInfo.data.CurrentEntry.AnsiRentSquareFoot)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Separation Date */
        if (cellInfo.column.dataField === 'InitAnsiUsable') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.AnsiUsableSquareFoot) !== JSON.stringify(cellInfo.data.CurrentEntry.AnsiUsableSquareFoot)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* User Region */
        if (cellInfo.column.dataField === 'InitAdjustedRent') {
            if (JSON.stringify(cellInfo.data.PreviousEntry.AdjustedRentSquareFoot) !== JSON.stringify(cellInfo.data.CurrentEntry.AdjustedRentSquareFoot)) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* Role */
        if (cellInfo.column.dataField === 'InitAdjustedUsage') {
            if (cellInfo.data.PreviousEntry.AdjustedUsageSquareFoot !== cellInfo.data.CurrentEntry.AdjustedUsageSquareFoot) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* User Status */
        if (cellInfo.column.dataField === 'InitBuildingJointUse') {
            if (cellInfo.data.PreviousEntry.BuildingJointUseSquareFoot !== cellInfo.data.CurrentEntry.BuildingJointUseSquareFoot) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* User Status */
        if (cellInfo.column.dataField === 'InitCommunityJointUse') {
            if (cellInfo.data.PreviousEntry.CommunityJointUseSquareFoot !== cellInfo.data.CurrentEntry.CommunityJointUseSquareFoot) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* User Status */
        if (cellInfo.column.dataField === 'InitRentUsage') {
            if (cellInfo.data.PreviousEntry.RentUsageFactor !== cellInfo.data.CurrentEntry.RentUsageFactor) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        /* User Status */
        if (cellInfo.column.dataField === 'InitCongressionalRep') {
            if (cellInfo.data.PreviousEntry.CongressionalRep !== cellInfo.data.CurrentEntry.CongressionalRep) {
                return (
                    <>
                        {cellInfo.value}
                        <InfoIconComponent item={cellInfo.value} key={cellInfo.value} />
                    </>
                );
            }
        }
        return cellInfo.value;
    }
}

const RenderTemplate = (values) => {
    return (
        <>
            {
                (values.PreviousEntry === null || values.ChangeType === 'Insert') ?
                    <>No Previous History, record was inserted.</>
                    :
                    <DataGrid
                        dataSource={[values]}
                        showBorders={true}
                        columnAutoWidth={true}
                    >
                        <Column dataField="InitRegion" caption="Region" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingNumber" caption="Building Changed" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingName" caption="Building Name" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingFacility" caption="Building Facility" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingStatus" caption="Building Status" cellRender={renderGridCell} />
                        <Column dataField="InitAddress" caption="Address" cellRender={renderGridCell} />
                        <Column dataField="InitAnsiRent" caption="ANSI Rent" cellRender={renderGridCell} />
                        <Column dataField="InitAnsiUsable" caption="ANSI Usable" cellRender={renderGridCell} />
                        <Column dataField="InitAdjustedRent" caption="Adjusted Rent" cellRender={renderGridCell} />
                        <Column dataField="InitAdjustedUsage" caption="Adjusted Usage" cellRender={renderGridCell} />
                        <Column dataField="InitBuildingJointUse" caption="Building Joint Use" cellRender={renderGridCell} />
                        <Column dataField="InitCommunityJointUse" caption="Community Joint Use" cellRender={renderGridCell} />
                        <Column dataField="InitRentUsage" caption="Rent Usage Factor" cellRender={renderGridCell} />
                        <Column dataField="InitCongressionalRep" caption="Congressional Rep" cellRender={renderGridCell} />
                    </DataGrid >
            }
        </>
    );
}
export default BuildingChanges;
