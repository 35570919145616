import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useRef, useState } from "react";
import { useEffect } from "react";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { LoadingIndicatorCircle } from "../../components/Loading";
import { DropDownBox, List, TextBox } from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useAuth } from '../../hooks/useAuth';
import GetFetch from "../../hooks/GetFetch";

export default function ApprovalMultiSelectDevEx({ value, valueSetter
}) {
    const approvalOptions = [{ id: 1, status: "Pending" }, { id: 2, status: "Approved" }, { id: 3, status: "Denied" }]
    const [filteredApprovalList, setFilteredApprovalList] = useState(approvalOptions);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState()


    const dropDownBoxRef = useRef(null);
    const selectionMode = "multiple";


    const onSearchValueChanged = (e) => {
        if (searchValue != e.value) {
            let filteredList = []
            let searchedVal = e.value;
            var list = filteredApprovalList.map((i) => i.status);
            var newVal = [];

            let valArray = typeof searchedVal === "string" && searchedVal !== '' ? searchedVal.split(/[\s+,]/) : null;
            if (typeof searchedVal === "string" && searchedVal === '') {
                setSelectedKeys([])
                valueSetter([])
                setFilteredApprovalList(approvalOptions)
                return;
            }

            if (valArray && valArray.length > 0) {

                valArray.forEach(element => {
                    let ele = element.toUpperCase();
                    if (list.indexOf(ele) >= 0) {
                        let val = filteredApprovalList.filter(x => x.status === ele);
                        newVal.push(val[0]);
                    }

                    filteredList = filteredList.concat(approvalOptions.filter(x => x.status.toUpperCase().includes(ele) && !filteredList.includes(x)))

                });
            }
            if (newVal.length > 0) {
                setSelectedKeys(newVal);
                valueSetter(newVal);

            }
            setFilteredApprovalList(filteredList);
            setSearchValue(e.value)
        }
    };

    const contentRender = (e) => {
        return (
            <React.Fragment>
                <TextBox
                    value={searchValue ?? ""}
                    onValueChanged={onSearchValueChanged}
                    placeholder="Search..."
                    showClearButton={true}
                />
                <List
                    selectedItemKeys={selectedKeys}
                    showSelectionControls={true}
                    selectionMode="multiple"
                    ref={dropDownBoxRef}
                    displayExpr="status"
                    onOptionChanged={onSelectedItemKeysChange}
                    pageLoadMode="scrollBottom"
                    showScrollbar="always"
                    useNativeScrolling={false}
                    height="400px"
                    dataSource={e}
                >
                </List>
            </React.Fragment>
        )
    };
    const onSelectedItemKeysChange = ({ name, value }) => {

        if (name === 'selectedItemKeys') {
            if (selectionMode !== 'none' || selectedKeys.length !== 0) {

                let newValue = value.map((approval) => approval.status);
                if (newValue && newValue[0] !== 'undefined') {
                    setSelectedKeys(value);
                    valueSetter(newValue);
                }
            }
        }
    };

    return (
        <div>
            {
                filteredApprovalList &&
                (
                    <FormControl sx={{ maxWidth: 200, marginRight: 5 }}>
                        <InputLabel id="fy-autocomplete-label"></InputLabel>
                        <div id="ApprovalDropDownBox" role="combobox" aria-haspopup="listbox" aria-expanded="false"
                            aria-live="polite" aria-owns="listOfOAs">
                            <DropDownBox
                                label="Approval Status(es)"
                                labelMode="floating"
                                height={56}
                                width={230}
                                value={value}
                                valueExpr="status"
                                displayExpr="status"
                                dataSource={approvalOptions}
                                ref={dropDownBoxRef}
                                tabIndex={0}

                            >
                                {contentRender(filteredApprovalList)}
                            </DropDownBox>
                        </div>
                    </FormControl>
                )}
        </div>
    );
}
