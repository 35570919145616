import { baseUrl } from "../util/Endpoint";
/**
 * This hook must be used with an 'await' operator, or it will fail. For Put, Post, and Patch api calls to backend.
 * @param {string} url - ex. '/v1/SummaryCostEstimate/SaveEstimate', Base URL is included in this hook.
 * @param {string} method - this can only be 'POST', 'PUT', or 'PATCH'
 * @param {object} data - Raw Data Object to be served to the backend, this method will stringify it for you.
 * @returns result of the api call
 */
const PutPostPatchFetch = async (
    url,
    method,
    data,
    contentType = 'application/json'
) => {
    let acceptedMethodString = ['POST', 'PUT', 'PATCH'];
    var errorMessage = method + ' is not supported for this hook. Only ' + acceptedMethodString.toString() + ' are accepted. Please adjust your code.';
    if (method === undefined || method === null) {
        throw new Error(errorMessage);
    }

    var methodIsGood = false;
    acceptedMethodString.forEach(element => {
        if (element === method) {
            methodIsGood = true;
        }
    })

    if (!methodIsGood) {
        throw new Error(errorMessage);
    }

    // construct header objects
    // construct headers
    let headersObject = {};
    // Get username
    let user = null;
    let unverifiedUser = null;
    let formattedData = data;
    try {
        const userHold = JSON.parse(localStorage.getItem("user"));
        const invalidUser = JSON.parse(localStorage.getItem("unverifiedUser"));
        user = userHold;
        unverifiedUser = invalidUser;
    }
    catch (ex) {
        console.error("Couldn't JSON parse the user local storage object:", ex);
    }

    if (contentType == 'multipart/form-data') {
        headersObject = {
            headers: {
                // Note: Content-Type': missing - let Browser set it
                'Authorization': "Bearer " + localStorage.getItem("JWT_TOKEN"),
                'From': (user !== null) ? user.email : unverifiedUser.email,
            }
        }
    } else {
        headersObject = {
            headers: {
                'Content-Type': contentType,
                'Authorization': "Bearer " + localStorage.getItem("JWT_TOKEN"),
                'From': (user !== null) ? user.email : unverifiedUser.email,
            }
        }
        // Note we only stringify if not multi-part form
        formattedData = JSON.stringify(data)
    }

    const requestOptions = {
        method: method,
        headers: headersObject.headers,
        body: formattedData
    }
    const result = await fetch(baseUrl() + url, requestOptions)
        .then(async (response) => {
            try {
                if (contentType == 'multipart/form-data') {
                    const resp = await response.json();
                    return resp;
                }
                else {
                    const resp = await response.json();
                    return resp;
                }
            }
            catch (ex) {
                return console.error("Jsoning failed", ex);
            }
        })
        .catch((ex) => {
            console.error(ex);
        });

    return result;
};

export default PutPostPatchFetch;