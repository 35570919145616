import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
    ROUTE_PSO_CONTRACT_SUMMARY_PAGE,
    ROUTE_VIEW_SINGLE_PSO_CONTRACT 
} from '../../routes/Routes';
import usePageTitle from "../../hooks/usePageTitle";
import GetFetch from "../../hooks/GetFetch";
import TabPanel from 'devextreme-react/tab-panel';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    GroupItem,
    GroupPanel,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from "../../components/CustomPreparedHeaderCell";
import { allowedPageSizes } from "../../components/GlobalDataGridConfigurations";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { generateUID } from "../../util/_helpers";
import { ItemFormComponent } from "./ItemFormComponent/ItemFormComponent";
import { ItemTitleComponent } from "./ItemTitleComponent/ItemTitleComponent";
import './PSO_Contract.scss';


const PSO_Contract = () => {
    const {
        state: {
            selectedRowData: {
                number,
                taskOrders
            } 
        } 
    } = useLocation();
    
    const [postExhibitDataSource, setPostExhibitDataSource] = useState();
    const [totalCosts, setTotalCosts] = useState();
    const [showDataGrid, setShowDataGrid] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    
    useEffect(() => {
        fetchPostExhibit(basePeriodTaskId);
        fetchAllTotalContractCosts();
    }, []);
    
    usePageTitle(`${ROUTE_VIEW_SINGLE_PSO_CONTRACT.title}: ${number}`);
    
    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_PSO_CONTRACT_SUMMARY_PAGE.withSlash);

    const mutatedTaskOrdersList = generateUID(taskOrders);
    const basePeriodTaskId = mutatedTaskOrdersList[0].TaskOrderId;
    const taskOrdersListArray = mutatedTaskOrdersList.concat({ TotalCosts: totalCosts });
    
    taskOrdersListArray.forEach((object, index) => {
        if (index === 0)  object.Title = 'Base Period';
        if (index > 0)    object.Title = `Option Period ${index}`;
        if (index === taskOrdersListArray.length - 1) { 
            object.Title          = 'Summary';
            object.TaskOrderCount = taskOrders.length;
        };
    });
    
    const onSelectionChanged = async ({ addedItems }) => fetchPostExhibit(addedItems[0].TaskOrderId);
    
    const fetchPostExhibit = async (param) => {
        try {
            const { Errors, Message, Success } = await GetFetch(`/v1/Post/GetPostsByTaskOrderId/${param}`);

            if (Success) {
                setShowDataGrid(true);
                setError(false);

                const data = Message.map(
                    (
                        { 
                            CLIN, 
                            Building: {
                                Name: Building_Name,
                                RegionNumber,
                                SequenceNumber
                            },
                            FeeType: {
                                FeeTypeName,
                            },
                            PostAllocations,
                            PostId, 
                            TotalServiceCosts
                        }
                    ) => {
                    /**
                     * @todo For now (temporarily), The DataGrid for posts will take only the first index 
                     * an allocation for AgencyName until this data is cleaned up. At which point, this
                     * may or will be refactored.
                     */
                        let Customer_Id, Customer_Name;

                        if (PostAllocations.length > 0) {
                            const { 
                                Customer: {
                                    CustomerId
                                },
                                CustomerName,
                            } = PostAllocations[0].OccupancyAgreement;
                            Customer_Id = CustomerId ?? "N/A";
                            Customer_Name = CustomerName ?? "N/A";
                        };
                       
                        return {
                            CLIN,
                            Customer_Id,
                            Customer_Name,
                            FeeTypeName,
                            Building_Name,
                            RegionNumber,
                            PostId,
                            SequenceNumber,
                            TotalServiceCosts,
                        };
                    }
                );

                setPostExhibitDataSource(data);
            };
            
            if (Errors.length > 0) {
                const { Message } = Errors[0];

                setShowDataGrid(false);
                setError(true);
                setErrorMessage(Message);
            };

        } catch (error) {
            console.error(error)
        }
    };
    
    const fetchAllTotalContractCosts = async () => {
        const taskOrderIdArray = taskOrders.map(( { TaskOrderId }) => {
            return TaskOrderId;
        });

        const responses = [];
        
        for (let index of taskOrderIdArray) {
            try {
                const { Errors, Message, Success } = await GetFetch(`/v1/Post/GetPostsByTaskOrderId/${index}`)

                if (Success) {
                    responses.push(Message);
                };

                if (Errors?.length < 0) console.error(Errors);

            } catch (error) {
                console.error(`Request failed: ${error}`)
            }
        }

        const flattenedCostsArray = responses?.flat();
        const mappedCosts = flattenedCostsArray?.map(
            (
                { TotalServiceCosts }
            ) => {
                return TotalServiceCosts;
            }
        );

        const totalSum = mappedCosts?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setTotalCosts(totalSum);
    };
    
    return (
        <>
            <div className="inline__div--wrapper">
                <h1> { ROUTE_VIEW_SINGLE_PSO_CONTRACT.title }: { number } </h1>
            </div>
            <div className="inline__div--wrapper">
                <BaseButton 
                    label={`Return to ${ ROUTE_PSO_CONTRACT_SUMMARY_PAGE.title }`}
                    onClick={navigateToRoute}
                    variant={'outlined'}
                />
            </div>
            <div className="inline__div--wrapper">
                <TabPanel 
                    height={194}
                    width={1000}
                    dataSource={taskOrdersListArray}
                    itemTitleRender={ItemTitleComponent}
                    itemComponent={ItemFormComponent}
                    onSelectionChanged={onSelectionChanged}
                />
            </div>
            <div className="inline-block__div--wrapper">
                {
                    showDataGrid &&
                    <>
                        <DataGrid 
                            columnAutoWidth={true}
                            onCellPrepared={CustomPreparedHeaderCell}
                            dataSource={postExhibitDataSource}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                        >
                            <Column 
                                dataField="PostId"
                                caption="Post ID"
                                allowGrouping={false}
                            />
                            <Column 
                                dataField="CLIN"
                                caption="CLIN"
                            />
                            <Column 
                                dataField="SequenceNumber"
                                caption="Building Number"
                            />
                            <Column 
                                dataField="Customer_Name"
                                caption="Customer Name"
                            />
                            <Column 
                                dataField="Building_Name"
                                caption="Building Name"
                            />
                            <Column 
                                dataField="RegionNumber"
                                caption="Region"
                            />
                            <Column 
                                dataField="FeeTypeName"
                                caption="Fee Type"
                            />
                            <Column 
                                dataField="Customer_Id"
                                caption="Customer Number"
                            />
                            <Column 
                                caption="Total PSO Costs"
                                dataField="TotalServiceCosts"
                                dataType="number"
                                format="currency"
                            />
                            <HeaderFilter visible={true} />
                            <FilterRow visible={true} />
                            <GroupPanel visible={true} />
                            <Summary>
                                <GroupItem 
                                    alignByColumn={true}
                                    column="TotalServiceCosts"
                                    summaryType="sum"
                                    valueFormat="currency"
                                />
                                <TotalItem 
                                    column="TotalServiceCosts"
                                    summaryType="sum"
                                    valueFormat="currency"
                                />
                            </Summary>
                            <KeyboardNavigation enabled={true} />
                            <SearchPanel
                                highlightCaseSensitive={true}
                                visible={true}
                            />
                            <Paging defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                            />
                        </DataGrid>
                    </>
                }
                {
                    error && 
                    <>
                        <p> { errorMessage } </p>
                    </>
                }
            </div>
        </>
    );
};

export default PSO_Contract;